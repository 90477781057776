import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inhome',
  templateUrl: './inhome.component.html',
  styleUrls: ['./inhome.component.css']
})
export class InhomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
