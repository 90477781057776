<!-- Footer -->
<footer class="bg-dark text-center text-white">
  <!-- Grid container -->
  <div class="container">
    <div class="row">
    <!-- Section: Links -->
    <section class="map col-md-12">
      <!--Grid row-->
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 text-center bordr">
          <img src="../../../assets/img/logo3.png" alt="">
        </div>
        <!--Grid column-->
        <div class="col-lg-3 col-md-3 col-sm-12 bordr">
          <h5 class="text-uppercase">SERVICES</h5>

          <ul class="list-unstyled">
            <li>
              <a routerLink="/wastewatertreatment" class="text-white">ENVIRONMENTAL</a>
            </li>
            <li>
              <a routerLink="/norm" class="text-white">NORM</a>
            </li>
            <li>
              <a routerLink="/lifting/supply" class="text-white">LIFTING</a>
            </li>
            <li>
              <a routerLink="/drilling/downhole" class="text-white">DRILLING</a>
            </li>
            <li>
              <a routerLink="/production" class="text-white">PRODUCTION</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-3 col-md-3 col-sm-12 bordr">
          <h5 class="text-uppercase">SITEMAP</h5>

          <ul class="list-unstyled">
            <li>
              <a routerLink="/" class="text-white">HOME</a>
            </li>
            <li>
              <a routerLink="/about" class="text-white">ABOUT US</a>
            </li>
            <li>
              <a routerLink="/qhse/cerifications" class="text-white">CERTIFICATES</a>
            </li>
            <li>
              <a routerLink="/news" class="text-white">NEWS</a>
            </li>
            <li>
              <a routerLink="/contact" class="text-white">CONTACT</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-3 col-md-3 col-sm-12 bordr">
          <h5 class="text-uppercase">CONTACT INFO</h5>

          <ul class="list-unstyled">
            <li>
              <a routerLink="/contact" class="text-white">Building 54 A, 6th Sector Zahraa El Maadi, Cairo Egypt.</a>
            </li>
            <li>
              <a routerLink="/contact" class="text-white">+(202) 25218289
                +(202) 25218345/6/7/8</a>
            </li>
            <li>
              <a routerLink="/contact" class="text-white">info@unico-egypt.com</a>
            </li>
          </ul>
          <div class="row footer-social">
            <a class="btn btn-outline-light btn-floating m-1" href="https://www.facebook.com/unicogroupegypt/" target="_blank" role="button"
            ><i class="fab fa-facebook-f"></i
            ></a>
            <!-- Google -->
            <a class="btn btn-outline-light btn-floating m-1" href="mailto:info@unico-egypt.com" target="_blank" role="button"
              ><i class="fab fa-google"></i
            ></a>
            <!-- Linkedin -->
            <a class="btn btn-outline-light btn-floating m-1" href="https://www.linkedin.com/company/united-oil-services-unico-f-z" target="_blank" role="button"
              ><i class="fab fa-linkedin-in"></i
            ></a>
          </div>
        </div>
        <!--Grid column-->


      </div>
    </section>
    <!-- Copyright -->
    <div class="text-center col-md-12" style="background-color: rgba(0, 0, 0, 0.2);">
      © {{ currentYear }} Copyright:
      <a class="copy-link" routerLink="/">unico-egypt.com</a>
    </div>
    </div>

  </div>
</footer>
<!-- Footer -->
