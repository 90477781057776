<section class="contact">
  <div class="contact-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">CONTACT US</h1>
      </div>
    </div>
  </div>
  <div class="contact-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">Get In Touch</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 left">
          <span data-aos="fade-down">Emails</span>
          <ul  data-aos="fade-right">
            <li>
              <b>Environmental services:</b><br>
              manal@unico-egypt.com
            </li>
            <li>
              <b>NORM services:</b><br>
              ahmed.farouk@unico-egypt.com
            </li>
            <li>
              <b>Lifting Inspection services:</b> <br>
              karam.mohamed@unico-egypt.com<br>
              <b>Lifting Supply services:</b> <br>
              sales@unico-egypt.com
            </li>
            <li>
              <b>Drilling services:</b> <br>
              hesham.hanafy@unico-egypt.com
            </li>
            <li>
              <b>Production services:</b> <br>
              m.eliwa@unico-egypt.com
            </li>
          </ul>
          <hr>
          <span  data-aos="fade-up">Phone Numbers</span>
          <ul  data-aos="fade-down">
            <li>
              <b>+(202) 25218289</b>
            </li>
            <li>
              <b>+(202) 25218345/6/7/8.</b>
            </li>
            <li>
              <b>Fax: +(202) 25218291</b>
            </li>
          </ul>
        </div>
        <div data-aos="fade-left" class="col-lg-6 col-md-12 right">
          <img src="../../../../assets/img/about/about1.jpg" alt="">
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">LOCATION</h3>
          <h4>HEAD OFFICE</h4>
          <span data-aos="fade-down">
            Building 54 A, 6th Sector Zahraa El Maadi, Cairo Egypt
          </span>
        </div>
      </div>

    </div>
    <div class="row">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3456.5891717699988!2d31.31519991463547!3d29.962493529395253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14583978c1ea1311%3A0xa0f99640f1b8eca2!2sUnited%20Oil%20Services%20Co.%20UNICO!5e0!3m2!1sen!2seg!4v1622241035872!5m2!1sen!2seg" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
  </div>
</section>
