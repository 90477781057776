<section class="ltraining">
  <div class="ltraining-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">LIFTING TRAINING</h1>
      </div>
    </div>
  </div>
  <div class="ltraining-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">LIFTING TRAINING</h3>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="content1 row">
        <div class="col-md-6 col-sm-12 left">
          <p data-aos="fade-up">
            Unico’s lifting team provides the following Training Courses at our Training Centre in Cairo or at our Client’s premises both onshore and offshore. Our Training courses consist of both Theoretical and Practical Sessions and at the end of the course the candidate is made to hold an exam which has an 80% pass mark upon successful completion, hence the candidate is issued with a Certificate of Achievement from Unico Lifting Services.
          </p>
        </div>
        <div class="col-md-6 col-sm-12 right">
          <img data-aos="fade-down" src="../../../../assets/img/qhse/certificates/LEEA-TRAINING.jpg" alt="">
        </div>
      </div>
      <hr>
      <div class="content row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">THE TRAINING REPRESENTED IN</h3>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div data-aos="fade-right" class="card-wrapper">
            <div class="card one">
            <div class="card-header">
          </div>
          <div data-aos="fade-down" class="content">
            <h3>Rigging And Slinging. (LEEA Accreditation)</h3>
            <p>By the course end delegates will be conversant with the principles of the correct use of lifting accessories, pre use check, hand signals and good rigging practice.</p>
          </div>
          </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div data-aos="fade-right" class="card-wrapper">
            <div class="card two">
            <div class="card-header">
          </div>
          <div data-aos="fade-down" class="content">
            <h3>Cargo Carrying Units (CCU) Inspection. (LEEA Accreditation)</h3>
            <p>By the course end delegates will be conversant with the principles of the correct use of lifting accessories, pre use check of lifting accessories and CCUs, Knowing the types of CCUs, how to inspect the CCUs and how to select the correct lifting set.</p>
          </div>
          </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div data-aos="fade-right" class="card-wrapper">
            <div class="card three">
            <div class="card-header">
          </div>
          <div data-aos="fade-down" class="content">
            <h3>Overhead Travelling Crane Inspection</h3>
            <p>By the course end delegates will be conversant with overhead crane types, the
              principles of overhead cranes inspection and overhead crane components.
              </p>
          </div>
          </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div data-aos="fade-right" class="card-wrapper">
            <div class="card four">
            <div class="card-header">
          </div>
          <div data-aos="fade-down" class="content">
            <h3>Forklift inspection &amp; Operation</h3>
            <p>By the course end delegates will be conversant with forklift truck types, the principles of forklift trucks inspection and forklift truck components and operation.</p>
          </div>
          </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div data-aos="fade-right" class="card-wrapper">
            <div class="card five">
            <div class="card-header">
          </div>
          <div data-aos="fade-down" class="content">
            <h3>Mobile Crane Inspection</h3>
            <p>By the course end delegates will be conversant with the mobile crane types,
              principles of mobile cranes inspection, mobile crane components, load charts
              and limit switches.
              </p>
          </div>
          </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div data-aos="fade-right" class="card-wrapper">
            <div class="card six">
            <div class="card-header">
          </div>
          <div data-aos="fade-down" class="content">
            <h3>LOLER And PUWER Awareness</h3>
            <p>By the course end delegates will be conversant with LOLER and PUWER
              regulations, instructions and responsibility.
              </p>
          </div>
          </div>
          </div>
        </div>
        <!--<div class="col-md-6 col-sm-12 right" data-aos="fade-left">
          <img src="../../../../assets/img/lifting/training/training-certifi.png" alt="">
        </div>
          <div class="col-md-6 col-sm-12 left" data-aos="fade-right">
            <ul>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> CRANE OPERATOR</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> RIGGING AND SLINGING</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> WIRE ROPE INSPECTION</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> SOCKET TERMINATION</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> RISK ASSESSMENT</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> LOLER AND PUWER AWARENESS</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> LIFTING EQUIPMENT INSPECTION</li>
            </ul>
          </div>-->
      </div>
      <div class="brief" data-aos="fade-down">
        <div class="text">
          <p class="container">
            IF YOU ARE INTERSTED TO ENROLL TO ONE OF OUR LIFTING TRAINING COURSES, KINDLY CONTACT US : 01025558300
          </p>
        </div>
      </div>
      <hr>
      <div class="tools row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">TRAINING PHOTOS</h3>
        </div>
        <div class="col-md-12">
          <div class="tools-img row">
            <div class="col-md-6" data-aos="fade-right">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/training/t1.png" alt="img17"/>
                        <figcaption>
                          <h2>LEAA <span> ACCREDITED INSTRUCTORS</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6" data-aos="fade-down">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/training/t2.jpg" alt="img17"/>
                        <figcaption>
                          <h2>THEORETICAL & <span> PRACTICAL SESSIONS</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6" data-aos="fade-left">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/training/t3.jpg" alt="img17"/>
                        <figcaption>
                          <h2>FREQUENT <span> FOLLOW UP & EXAMINATION</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6" data-aos="fade-right">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/training/t4.png" alt="img17"/>
                        <figcaption>
                          <h2>CERTIFICATE <span> OF ACHIEVEMENT</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
