<div class="wrapper">
  <!-- Sidebar  -->
  <nav id="sidebar">
    <div class="sidebar-header" id="header">
      <img src="../../../../assets/img/logo2.png">
    </div>
    <ul class="list-unstyled components">

    <li id="h" class="active"><a href="/dashboardhome"><img src="https://img.icons8.com/material-rounded/24/000000/home.png"> Dashboard Home</a></li>
    <hr>
    <div *ngFor="let uinfo of userinfo">
      <li id="add-announce" *ngIf="uinfo.name == 'Khaled Farouk' || uinfo.name == 'Fatma Farouk' || uinfo.name == 'Mohamed Bauomy' || uinfo.name == 'Manal'"><a routerLink="/announcement"><img src="https://img.icons8.com/ios/24/ffffff/add-property.png"/> Add Announcement</a></li>
    </div>
    <hr>
    <li id="form"><a routerLink="/forms"><img src="https://img.icons8.com/wired/24/ffffff/microsoft-forms.png"/> Forms</a></li>
    <hr>
    <div *ngFor="let uinfo of userinfo">
      <li id="report" *ngIf="uinfo.type == 'manager' || uinfo.type == 'dmanager'">
        <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><img src="https://img.icons8.com/carbon-copy/24/ffffff/business-report.png"/> Reports</a>
        <ul class="collapse list-unstyled" id="homeSubmenu">
          <li *ngIf="uinfo.type == 'manager' || uinfo.type == 'dmanager'">
            <a href="javascript:void(0)" routerLink="/managerReport"><img src="https://img.icons8.com/ios/24/ffffff/agreement.png"/> Manager Report</a>
          </li>
          <li *ngIf="uinfo.type == 'manager' || uinfo.type == 'hr'">
            <a href="javascript:void(0)" routerLink="/hrReport"><img src="https://img.icons8.com/ios/24/ffffff/test-results.png"/> HR Report</a>
          </li>
        </ul>
      </li>
    </div>
    <hr>
    <li id="manual">
      <a href="#homeSubmenu11" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><img src="https://img.icons8.com/ios/24/ffffff/user-manual.png"/> Manual</a>
      <ul class="collapse list-unstyled" id="homeSubmenu11">
        <!--<li>
          <a href="javascript:void(0)" class="nav-link disabled" routerLink="/manual"><img src="https://img.icons8.com/ios/24/ffffff/book.png"/> Job Description Manual</a>
        </li>
        <li>
          <a href="javascript:void(0)" class="nav-link disabled" routerLink="/organizationChart"><img src="https://img.icons8.com/ios/24/ffffff/organization-chart-people.png"/> Organization Chart</a>
        </li>-->
        <li>
          <a href="javascript:void(0)" routerLink="/unicoprofile"><img src="https://img.icons8.com/ios/24/ffffff/classroom.png"/> Unico Profile</a>
        </li>
      </ul>
    </li>
    <hr>
    <li id="regulation"><a routerLink="/requlations"><img src="https://img.icons8.com/dotty/24/ffffff/people-working-together.png"/> Work Regulations</a></li>
    <hr>
    <!--<li><a routerLink="/events" class="nav-link disabled"><img src="https://img.icons8.com/ios/24/ffffff/event-accepted.png"/> Events & Photos</a></li>
    <hr>-->
    <li id="space">
      <a style="font-weight: 700;" href="#homeSubmenu111" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"><img src="https://img.icons8.com/ios/24/ffffff/ball-point-pen.png"/> My Space</a>
      <ul class="collapse list-unstyled" id="homeSubmenu111">
        <li>
          <a href="javascript:void(0)" routerLink="/notes"><img src="https://img.icons8.com/ios/24/ffffff/notepad.png"/> Notes</a>
        </li>
        <li>
          <a href="javascript:void(0)" routerLink="/todolist"><img src="https://img.icons8.com/ios/24/ffffff/reminders.png"/> To-Do List</a>
        </li>
        <li>
          <a href="javascript:void(0)" routerLink="/calender"><img src="https://img.icons8.com/ios/24/ffffff/timeline-week.png"/> Calendar</a>
        </li>
      </ul>
    </li>
    </ul>
  </nav>

  <!-- Page Content  -->
  <div id="content">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand" href="javascript:void(0)">
        <button type="button" id="sidebarCollapse" class="btn btn-info" style="background-color: #bf1e2d;">
          <i class="fas fa-align-left"></i>
          <span></span>
        </button>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a class="nav-link" routerLink="/"><img src="https://img.icons8.com/material-rounded/40/ffffff/home.png"> Website Home <span class="sr-only">(current)</span></a>
          </li>
          <!--<li class="nav-item" *ngFor="let uinfo of userinfo">
            <a style="cursor: none;" class="nav-link" href="javascriot:void(0)"><img src="https://img.icons8.com/ios/40/ffffff/employee-card.png"/> {{ uinfo.name }}</a>
          </li>-->
          <!---->
          <li class="nav-item dropdown" id="notification">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink1" data-toggle="dropdown" aria-haspopup="true">
              <img src="https://img.icons8.com/ios/40/ffffff/push-notifications.png"/> Notifications <span class="all-notify">{{ calendarnotificationNumber + announcenotificationNumber }}</span> <img style="width: auto;margin-top: -5px;" src="https://img.icons8.com/material-outlined/24/000000/expand-arrow--v1.png"/>
            </a>
            <div class="dropdown-menu dropdown-menu-notfy" aria-labelledby="navbarDropdownMenuLink1">

                <div id="accordion">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          New Announcements <span>{{ announcenotificationNumber }}</span>
                        </button>
                      </h5>
                    </div>

                    <div id="collapseOne" class="collapse notify-content" aria-labelledby="headingOne" data-parent="#accordion">
                      <div class="card-body">
                        <ul>
                          <li *ngFor="let not of announceNotify" class="row">
                            <p class="col-md-8" style="color: #202e31;font-weight: 400;line-height: 1.8em;text-transform: capitalize;"><img src="https://img.icons8.com/color/15/000000/circled-dot.png" alt="unico-egypt"> {{ not.title }}</p>
                            <span class="col-md-4" (click)="announceNotifyDel(not)">Mark as read</span>
                            <hr class="col-md-12">
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Today's Events <span>{{ calendarnotificationNumber }}</span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                      <div class="card-body">
                        <ul>
                          <li *ngFor="let cal of newCalendarNotify" class="row">
                            <p class="col-md-12" style="color: #202e31;font-weight: 400;line-height: 1.8em;text-transform: capitalize;"><img src="https://img.icons8.com/color/15/000000/circled-dot.png" alt="unico-egypt"> Today you have an event is {{ cal.text }}</p>
                            <!--<span class="col-md-4" (click)="calNotifyDel(cal)">Mark as read</span>-->
                            <hr class="col-md-12">
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

            </div>
          </li>
          <!---->
          <li id="nameuser" class="nav-item dropdown" *ngFor="let uinfo of userinfo">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="https://img.icons8.com/ios/40/ffffff/user-menu-male.png"/> {{ uinfo.name }} <img style="width: auto;margin-top: -5px;" src="https://img.icons8.com/material-outlined/24/000000/expand-arrow--v1.png"/>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item btn btn-danger" href="javascriot:void(0)" (click)="logout()"><img src="https://img.icons8.com/ios/24/000000/logout-rounded--v1.png"/> Logout</a>
              <a style="display: none;" class="dropdown-item" href="javascriot:void(0)" data-toggle="modal" data-target="#exampleModal"><img src="https://img.icons8.com/ios/24/000000/power-off-button--v2.png"/> Final Logout</a>
              <hr>
              <a class="dropdown-item" href="javascriot:void(0)" routerLink="/changepass"><img src="https://img.icons8.com/ios/24/000000/re-enter-pincode.png"/> Change Password!</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <!--Logout Modal-->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Logout Form Registration</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Please Write Down What You Did Today?</label>
              <textarea class="form-control" id="exampleFormControlTextarea1" #text rows="5"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="finalLogout(text.value)">Logout Now</button>
          </div>
        </div>
      </div>
    </div>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
</div>
