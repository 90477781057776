<section class="norm">
  <div class="norm-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">Spooler Machine</h1>
      </div>
    </div>
  </div>
  <div class="norm-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">ESP CABLE DECONTAMINATION SPOOLER MACHINE</h3>
        </div>
        <div class="content overview row">
          <div class="text col-md-6 col-sm-12">
            <p data-aos="fade-up"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
              UNICO‘s special technology is now available to decontaminate ESP NORM Decontaminated Cables with high safe standards over Normal & Galvanized Cables.
            </p>
            <hr>
            <p data-aos="fade-down"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
              UNCO Spooler Machine of ESP Cable NORM Decontamination is designed as a closed isolated system which protects
              the outer Environment from debris spreading and decontaminated the ESP cable efficiently with cable material
              protection.<br>
              UNICO Spooler machine is featured with Emergency Integrated Safety System meeting safety
              requirements in Oil and Gas industry.
            </p>
          </div>
          <div class="img col-md-6 col-sm-12">
            <img data-aos="fade-left" src="../../../../../assets/img/norm/n66.jpg" alt="SPOOLER MACHINE">
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="content overview row">
          <div class="img col-md-6 col-sm-12">
            <img data-aos="fade-left" src="../../../../../assets/img/norm/n7.jpg" alt="SPOOLER MACHINE">
          </div>
          <div class="text col-md-6 col-sm-12">
            <p data-aos="fade-up"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
              The ESP cables NORM contamination is required to be removed due to the deposit of the sludge, scales and NORM
              residuals on the surface of the cable. The cables reading varies according to the concentration of Norm on the cables
              during the oil production and the time of which the cables are being in contact with the oil.
            </p>
            <hr>
            <p data-aos="fade-down"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
              Most contaminated
              cables are radioactive with alpha and beta particles from radionuclides sources deposited over them .Hence; UNICO
              manufactured the spooler machine to decontaminate the NORM to BGL with reversible mechanism technology.
            </p>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="content overview row">
          <div class="text col-md-6 col-sm-12">
            <p data-aos="fade-up" style="line-height: 2;font-size: 23px;"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
              The decontamination operation is done according to the type of the ESP cable either cylindrical or flat and the thickness of the NORM residuals over the cable.<br>The two main techniques used are;
            </p>
          </div>
          <div class="img col-md-6 col-sm-12">
            <img data-aos="fade-left" src="../../../../../assets/img/norm/n5.jpg" alt="SPOOLER MACHINE">
          </div>
        </div>
      </div>
      <div class="row">
        <div data-aos="fade-down" id='main2'>
          <div id='info'>
            <div id='name'>
              <span>Buffering Techniques: The technique is used to decontaminate the cable of the cylindrical type cable and achieving high friction force especially of scales deposits.
              </span>
            </div>
          </div>
        </div>
        <div data-aos="fade-down" id='main2'>
          <div id='info'>
            <div id='name'>
              <span>Dustless Technique: Use high pressure water jet mixed with sand and it is considered an upgraded system of UNICO’s Spooler Machine as an additional Integrated System.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
