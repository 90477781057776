<section class="certificate">
  <div class="certificate-header">
    <section class="signinwrapper">
      <div class="signinpanel">
        <div class="container">
          <div class="form-welcome-wrapper row">
            <div class="col-md-8 col-sm-12">
              <img class="logo" src="../../../../assets/img/logo.jpg" width="80">
              <h1>Supply Quotation</h1>
            </div>
            <div class="col-md-4 col-sm-12" style="border-left: 1px solid;text-align: center;">
              <div class="righ-quo">
                <span>To Request a Quotation Kindly</span><br>
                <span>Click Here</span>
              </div>
              <a href="mailto:sales@unico-egypt.com?subject=Supply Quotation" target="_blank" class="system-btn">Quotation</a>
            </div>
          </div>
        </div>
      </div>
      </section>
  </div>
</section>
