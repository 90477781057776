<section class="about">
  <div class="about-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">ABOUT UNICO</h1>
      </div>
    </div>
  </div>
  <div class="about-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">UNICO HISTORY</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 left">
          <p data-aos="fade-up">
            <b>United Oil Services (UNICO Group)</b> is an oil field company established in 1993. Our long road towards bringing services to the oil and gas industry was driven by experience, persistence and teamwork.
          </p>
          <hr>
          <p data-aos="fade-down">
            <b>Our Mission</b> is to provide quality services to the upstream oil and gas companies through our specialized departments. We aim to continue exploring the many services that accommodate to the oil and gas industry.
          </p>
          <hr>
          <p data-aos="fade-down">
            Paving the way to a better environment is a key factor in our work ethic and we continue to do so under a certified work management system (ISO 9001, ISO 14001 and ISO 45001 certificates).
          </p>
        </div>
        <div data-aos="fade-left" class="col-lg-6 col-md-12 right">
          <img src="../../../../assets/img/about/about.jpg" alt="">
        </div>
        <div class="col-lg-12 col-md-12 bottom">
          <p data-aos="fade-down">
            <b>UNICO’s Team</b> is a true indicator of a community working together to bring a better tomorrow. At Unico’s headquarters and at Unico’s sites we ensure that every team member is brining forward their best contribution.
          </p>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">UNICO LOCATIONS</h3>
        </div>
      </div>
      <div class="row">
        <div data-aos="fade-right" class="col-lg-4 col-md-6 col-sm-12">
          <div class="card-wrapper">
            <div class="card">
            <div class="image-wrapper">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13826.357331088986!2d31.3173886!3d29.9624889!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa47493ec02a3071f!2z2YrZiNmG2YrZg9mIIC0g2KfZhNi02LHZg9ipINin2YTZhdiq2K3Yr9ipINmE2K7Yr9mF2KfYqiDYp9mE2KjYqtix2YjZhA!5e0!3m2!1sen!2seg!4v1622986216062!5m2!1sen!2seg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <div data-aos="fade-down" class="content-wrapper">
              <div class="title">
                <h4>Head Office at Cairo: </h4>
                <p>Building 54 A, 6th Sector Zahraa El Maadi, Cairo Egypt.</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div data-aos="fade-down" class="col-lg-4 col-md-6 col-sm-12">
          <div class="card-wrapper">
            <div class="card">
            <div class="image-wrapper">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13675.84572370495!2d29.771171!3d31.0273242!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa7c014a6cec5a471!2sUnico%20Oil%20Services%20FreeZone!5e0!3m2!1sen!2seg!4v1623769553573!5m2!1sen!2seg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <div data-aos="fade-down" class="content-wrapper">
              <div class="title">
                <h4>Amreya Office: </h4>
                <p>Road. 9 Free Zone – El Amreya, Alexandria.</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div data-aos="fade-down" class="col-lg-4 col-md-6 col-sm-12">
          <div class="card-wrapper">
            <div class="card">
            <div class="image-wrapper">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6837.837570537459!2d29.7695563!3d31.0285123!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6ca995a1a0e82b47!2sUnico%20Petroleum%20Production%20FreeZone!5e0!3m2!1sen!2seg!4v1623769419605!5m2!1sen!2seg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <div data-aos="fade-down" class="content-wrapper">
              <div class="title">
                <h4>Amreya Office: </h4>
                <p>Road. 9 Free Zone – El Amreya, Alexandria.</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <!---->
        <div data-aos="fade-down" class="col-lg-4 col-md-6 col-sm-12">
          <div class="card-wrapper">
            <div class="card">
            <div class="image-wrapper">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3407.2699977912457!2d32.08156131514647!3d31.351527781425762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzHCsDIxJzA1LjUiTiAzMsKwMDUnMDEuNSJF!5e0!3m2!1sen!2seg!4v1625675477039!5m2!1sen!2seg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <div data-aos="fade-down" class="content-wrapper">
              <div class="title">
                <h4>Port Said: </h4>
                <p>Al Manasrah, Port Said Governorate</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div data-aos="fade-down" class="col-lg-4 col-md-6 col-sm-12">
          <div class="card-wrapper">
            <div class="card">
            <div class="image-wrapper">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.731118536852!2d26.97514771513073!3d30.697840481649404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1463ce2f1080b41f%3A0x77f31737b989db34!2sKhalda%20Petroleum%20Company%20-%20Peace%20field!5e0!3m2!1sen!2seg!4v1625675594250!5m2!1sen!2seg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <div data-aos="fade-down" class="content-wrapper">
              <div class="title">
                <h4>Khalda Apache - Peace field: </h4>
                <p>Matrouh Governorate</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <!---->
        <div data-aos="fade-left" class="col-lg-4 col-md-6 col-sm-12">
          <div class="card-wrapper">
            <div class="card">
            <div class="image-wrapper">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27944.301807685697!2d33.185282!3d28.897227!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd93584664fb7e4e9!2z2KfYqNmIINix2K_ZitizU2NodWxtYmVyZ2VyIFBldHJvYmVs!5e0!3m2!1sen!2seg!4v1623509580478!5m2!1sen!2seg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <div data-aos="fade-down" class="content-wrapper">
              <div class="title">
                <h4>Abu Rudeis Site: </h4>
                <p>On site treatment at Petrobel in Sinai.</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div data-aos="fade-down" class="col-lg-4 col-md-6 col-sm-12">
          <div class="card-wrapper">
            <div class="card">
            <div class="image-wrapper">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.327954018752!2d29.618391284869098!3d30.709179481645435!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzDCsDQyJzMzLjEiTiAyOcKwMzYnNTguMyJF!5e0!3m2!1sar!2seg!4v1622988609526!5m2!1sar!2seg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <div data-aos="fade-down" class="content-wrapper">
              <div class="title">
                <h4>Burg El Arab Site: </h4>
                <p>Matroh governorate – El Hammam – 4.5 Km.</p>
              </div>
            </div>
          </div>
          </div>
        </div>
        <div data-aos="fade-down" class="col-lg-4 col-md-6 col-sm-12">
          <div class="card-wrapper">
            <div class="card">
            <div class="image-wrapper">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3425.754073823957!2d29.953420084866035!3d30.837556381600752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzDCsDUwJzE1LjIiTiAyOcKwNTcnMDQuNCJF!5e0!3m2!1sar!2seg!4v1622988993419!5m2!1sar!2seg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <div data-aos="fade-down" class="content-wrapper">
              <div class="title">
                <h4>Al-Assad Site: </h4>
                <p>Alexandria Cairo desert road km 59 behind El Assad resort.</p>
              </div>
            </div>
          </div>
          </div>
        </div>

      </div>
      <hr>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">TAX INFORMATION</h3>
        </div>
      </div>
      <div class="row tax">
        <div class="col-md-6 col-sm-12 text-center">
          <h4 data-aos="fade-right">UNITED OIL SERVICES UNICO FREEZONE</h4><br><hr><hr>
          <h4 data-aos="fade-right">الشركة المتحدة لخدمات البترول - يونيكو</h4><br><hr><hr>
          <h4 data-aos="fade-left">TAX NUMBER: 706-012-127</h4>
        </div>
        <div class="col-md-6 col-sm-12">
          <img src="../../../assets/img/Tax Card-Unico.jpeg" alt="">
        </div>
      </div>
    </div>
  </div>
</section>>
