import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rdm',
  templateUrl: './rdm.component.html',
  styleUrls: ['./rdm.component.css']
})
export class RdmComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
