<section class="onsite">
  <div class="onsite-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">ON SITE TREATMENT</h1>
      </div>
    </div>
  </div>
  <div class="onsite-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">ON SITE TREATMENT</h3>
        </div>
      </div>
      <div class="content row">
        <div class="col-md-12">
          <p data-aos="fade-up">
            Unico will be able to treat oil base mud cutting on place at any client’s given location. Our team will install, monitor, and treat the given conditions to reach optimum results.
          </p>
        </div>
        <div class="col-md-6 col-sm-12 left">
          <h3 data-aos="fade-up">Benefits of specialized on site treatment:</h3>
          <ul data-aos="fade-up">
            <li><img src="../../../../assets/img/icons/dot-logo.png"> Reduces Cost.</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png"> Hassle Free.</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png"> No Transportation Hazards or Fees.</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png"> Reduction of Hazardous Pollutants.</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png"> Efficiency of Treatment Due to the Detailed Customization of the On-Site Treatment and Team Consistently Monitoring and Improving Specific Conditions.</li>
          </ul>
        </div>
        <div class="col-md-6 col-sm-12 right">
            <img data-aos="fade-left" src="../../../../assets/img/environment/incinerator/abuRdis.jpeg" alt="">
            <span data-aos="fade-up">Location: On Site at Client’s Location,<br>Abu Rudeis, on site treatment at Petrobel in Sinai</span>
        </div>
      </div>
    </div>
  </div>
</section>
