<div class="videoContainer hide-for-small-only">
  <div class="overlay">
    <div class="content row">
      <div class="col-md-12 col-sm-12 article" data-aos="fade-up">
        <h1 class="glowIn">WASTEWATER TREATMENT</h1>
        <h4 class="glowIn">for Oil & Gas</h4>
        <p class="col-md-12 col-sm-12 glowIn" data-aos="fade-up-right">
          Unico has been proudly chosen to become the first in the region to design, build and operate the one of a kind Waste Water Treatment Plant associated with oil and gas production. Our solutions exist in two different forms; the main treatment facility which is located along the shoreline of western Port Said or through customizing and installing portable units that accommodate to client specifications in various locations.
        </p>
      </div>
    </div>
  </div>
   <video id="vid" oncanplay="this.muted=true" loop="loop" autoplay preload="true">
      <source src="../../../../assets/vid/video.mp4" type="video/mp4">
    </video>
</div>
<section class="waste">
  <div class="waste-detail-bac">
    <header class="header-design">
      <div class="listar-map-button" data-aos="fade-up">
        <div class="listar-map-button-text" style="display: inline-block; opacity: 1;">
          <span class="icon-map2">
            WWTP PORT SAID </span>
        </div>
      </div>

      <div class="footer-wave"></div>
    </header>
    <div class="container content">
      <div class="row">
        <div class="col-md-6 col-sm-12" data-aos="fade-down">
          <img src="../../../../assets/img/h4.png" alt="Loading...">
        </div>
        <div class="col-md-6 col-sm-12" data-aos="fade-right">
          <p>
            Partnering with leading international entities in waste water management; Unico, Italprogetti, and Spark successfully executed this plant with the highest standards and safety considerations. Waste water treatment has become one of Unico’s present and future goals to help minimize environmental hazards as well as utilize the usage of water.
          </p>
          <hr>
          <p>
            The facility is built on 10,000 m<sup>2</sup> allowing for the treatment of 2,000/3,000 m<sup>3</sup> of waste water per day, while the storing capacity is up to 5,000 m<sup>3</sup> a day. With the completion of the plant after two years of consistent work, Unico has finally finished running its first trial period. Our goal has stemmed from the lack of inefficient waste water management.
          </p>
        </div>
        <div class="col-md-6 col-sm-12" data-aos="fade-left">
          <p>
            Successfully, the trial period has proven the system’s ability to treat all the received water from the neighboring geographical territory.
          </p>
          <p>
            The disposal of untreated waste water causes great environmental hazards while also hindering the use of water. We eventually begin to see great effects on underground aquifers, air pollution and any of the surrounding media where the disposal takes place.
          </p>
          <hr>
          <p>
            Therefore, gladly EGPC took the initiative through one of its companies and SSCO in one of the most active areas of oil and gas production in Port Said, Egypt and the support of the private sector’s experience and investment.
          </p>
        </div>
        <div class="col-md-6 col-sm-12" data-aos="fade-left">
          <iframe  src="https://www.youtube.com/embed/OVYiVbGTARU?rel=0" frameborder="0" allow="autoplay; encrypted-media"  allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>

  <div class="waste_cards">
    <div class="container">
      <div data-aos="zoom-in-down" class="title col-md-12">
        <h3>TREATMENT PROCESSES</h3>
      </div>
      <div class="flex row">
        <div class="col-lg-6 col-md-12">
          <div class="card one" data-aos="fade-right">
            <div class="side">
              <h1>1ST DAF Unit</h1>
            </div>
            <div class="header">
                <div class="title">
                  <!--
                  <div>
                    <h1>1ST DAF Unit</h1>
                  </div>
                -->
              </div>
            </div>

            <div class="content" data-aos="fade-down">
              <h3>Tri-Technology</h3>
              <ul>
                <li>
                  Physical, chemical and biological treatment to achieve reach requirements of Egyptian Environmental Law through lab analysis.
                </li>
                <li>
                  30% less consumption of energy due to advanced automated process, minimum man power and quality control system.
                </li>
                <li>
                  Adapted equipment to control odors
                </li>
                <li>
                  Storage built with GLS to reduce the weight of the tank to allow for larger capacity of treated water.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="card two" data-aos="fade-left">
            <div class="side">
              <h1>Mechanical Filters</h1>
            </div>
            <div class="header">
                <div class="title">
                  <!--
                  <div>
                    <h1>Mechanical Filters</h1>
                  </div>
                  -->
              </div>
            </div>
            <div class="content" data-aos="fade-down">
              <h3>Our Processes</h3>
              <ul>
                <li>Receiving and handling of effluent</li>
                <li>Physical filtration</li>
                <li>Homogenization and Oxidation</li>
                <li>Chemical Treatment</li>
                <li>Biological Treatment</li>
                <li>Final Disposal</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="portabl">
    <div class="container">
      <div class="row">
        <h3 data-aos="fade-right" class="col-md-4">PORTABLE UNITS</h3>
        <div class="col-md-8 text" data-aos="fade-down">
          <p>
            Based on diverse water characteristics and scattered locations, Unico offers customized and portable units fitting specific water parameters after pilot scale trial. This portable unit located on site would directly target the exact problem areas facing waste water produced by each client, resulting in the design, build, and operations by Unico’s specialized team. Our R&D team continues to monitor and develop the portable unit according to changing water parameters implementing any needed modification and updating the technology.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="vision">
    <div class="container">
      <div class="row">
        <h3 data-aos="fade-right" class="col-md-12">VISION</h3>
        <div class="text col-md-12" data-aos="fade-up">
          <p>
            Unico’s vision is to overcome all producing companies’ obstacles of waste water, which is an associated burden for oil and gas production. We aim to achieve ultimate solutions for waste water to make change towards water usage, allowing for recycling and reuse awareness to become part of our role towards preserving the environment and its natural resources.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="plan">
    <div class="container">
      <div class="row">
        <div data-aos="fade-up" class="left col-md-12 col-sm-12">
          <h3>EXPANSION PLAN</h3>
        </div>
        <div class="vertical-timeline">
          <ul>
            <li data-aos="fade-right">
              <div class="vt-icon">1</div>
              <div class="vt-item">
                <p>
                  Installation of RO units to reduce the water salinity to meet the requirements of water suitable for industrial purposes and wooden agriculture plants.
                </p>
              </div>
            </li>
            <li data-aos="fade-left">
              <div class="vt-icon">2</div>
              <div class="vt-item">
                <p>
                  Replace input and output water tankers with pipelines to ease handling functions.
                </p>
              </div>
            </li>
            <li data-aos="fade-up">
              <div class="vt-icon">3</div>
              <div class="vt-item">
                <p>
                  Replicating the current treatment stages to achieve maximum treatment ability of the plant according to the initial plant design.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
