<!--<div class="container">
  <div class="pdf-container">
    <pdf-viewer [src]="src"
                [original-size]="false"
    ></pdf-viewer>
  </div>
</div>
https://unico-egypt.com/assets/img/dashboard/internal_work_reg.pdf#toolbar=0
-->
<div class="text-center">
  <h1>job description manual</h1>
</div>
<embed
    type="application/pdf"
    src="https://unico-egypt.com/assets/img/dashboard/internal_work_reg.pdf#toolbar=0"
    width="100%"
    height="100%" />