<section class="about_inspection">
  <div class="about_inspection-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">LIFTING INSPECTION SERVICES</h1>
        <div class="desc">
          <p data-aos="fade-down">
            UNICO Lifting Services has been providing lifting services and solutions for over two decades. We pride ourselves in offering our clients a professional service and provide wide range of high quality products, supply tools and inspection services to meet all your requirements 24 hours a day
  365 days a year. In addition our services are designed to assist our customers to improve safety, increase productivity and operational savings.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="about_inspection-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">ABOUT INSPECTION SERVICES</h3>
        </div>
      </div>
    </div>
    <div class="brief" data-aos="fade-down">
      <div class="text">
        <p class="container">
          It is necessary to inspect any lifting equipment frequently to give our clients their appropriate certification to ensure and maintain safe lifting operations.
        </p>
      </div>
    </div>
    <hr>
    <div class="container">
      <div class="content row">
          <div class="col-md-6 col-sm-12 left" data-aos="fade-right">
            <h2>Testing, Repair and Refurbishment</h2>
            <p>
              Lifting Equipment requires constant and thorough examination every six and 12 months in accordance with the examination scheme.

A 100 ton test bed is available to test all types of slings and chains. We have the potential to repair all types of slings and refurbish snatch blocks and chain blocks with a quick turnaround. Every item of equipment supplied is tested or repaired and dispatched with an individual, fully traceable test certificate detailing the exact working load limit and proof load applicable to the item.
            </p>
          </div>
          <div class="col-md-6 col-sm-12 right" data-aos="fade-left">
            <img src="../../../../assets/img/lifting/about/Lifting-test.png" alt="">
          </div>
      </div>
      <hr>
      <div class="content1 row">
        <div class="col-md-6 col-sm-12 left" data-aos="fade-up">
          <img src="../../../../assets/img/lifting/about/hire.jpg" alt="">
        </div>
        <div class="col-md-6 col-sm-12 right" data-aos="fade-down">
          <h2>Equipment Hire</h2>
          <p>
            Hiring lifting equipment is the solution for short-term period that could accomplish the demand of a single item, Based on the client’s needs for cost control.
          </p>
          <hr>
          <p>
            We offer Water bags and Telemetric Load cells, providing specialist proof load testing solutions for lifting equipment and structures. Our core commitment is to provide Load testing products and services to the highest standards for our inspector usage and for hiring.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
