<section class="news">
  <div class="news-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">UNICO NEWS</h1>
      </div>
    </div>
  </div>
  <div class="news-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">OUR RECENT NEWS</h3>
        </div>
      </div>
      <div class="row cards">
        <!--<div data-aos="fade-up" class="col-lg-4 col-md-6 col-sm-12">
          <figure class="snip1360">
            <span style="position: absolute;left: 10px;top: 10px;background-color: red;color: white;padding: 5px;border-radius: 5px;font-weight: 600;">New</span>
            <img src="https://www.huber.de/fileadmin/_processed_/2/d/csm_Schaubild_Uebersicht_Zentrale_Klaeranlage_8_RGB_klein_78f9916279.jpg" alt="sample88" />
            <figcaption data-aos="fade-down">
              <h2>UNICO WWTP AND PETROBEL SIGN 2 YEAR TREATMENT CONTRACT</h2>
              <p>Unico has succeeded in signing its first contract with PETROBEL to treat the water associated with the production of oil and natural gas through SSCO.  This is due to the highest levels of quality resulting from Unico’s treatment and technology used for the first time in Egypt and the Middle East; the Tri-Technology. It is considered the first real operating contract that extends for to two years starting from 18/2/2021 taking place in Unico’s WWTP in Port Said.</p>
              <a href="javascript:void(0)" class="read-more" data-toggle="modal" data-target="#news6">Read More</a>
            </figcaption>
          </figure>
        </div>
        <div data-aos="fade-up" class="col-lg-4 col-md-6 col-sm-12">
          <figure class="snip1360">
            <span style="position: absolute;left: 10px;top: 10px;background-color: red;color: white;padding: 5px;border-radius: 5px;font-weight: 600;">New</span>
            <img src="../../../assets/img/news/khalda.jpg" alt="sample88" />
            <figcaption data-aos="fade-down">
              <h2>SIX YEARS OF UNICO AND KPC</h2>
              <p>Unico NORM, one of our free zone companies has been awarded once again the NORM contract as the main contractor with KPC for the third time in six consecutive years. To many successes for UNICO’s NORM department!</p>
              <a href="javascript:void(0)" class="read-more" data-toggle="modal" data-target="#news7">Read More</a>
            </figcaption>
          </figure>
        </div>-->
        <div data-aos="fade-up" class="col-lg-4 col-md-6 col-sm-12">
          <figure class="snip1360">
            <img src="../../../assets/img/news/5.png" alt="sample88" />
            <figcaption data-aos="fade-down">
              <h2>Halliburton Renew Contract</h2>
              <p>Following years of success, the renowned Halliburton has just renewed its contract with Unico's Lifting Services. The quality of our services and efficiency of our experts has granted us such great collaborations. Our Inspection services will continue to provide the highest levels of safety and accuracy towards our inspection duties and licensing. Congratulations to Unico's very own Lifting department.</p>
              <a href="javascript:void(0)" class="read-more" data-toggle="modal" data-target="#news5">Read More</a>
            </figcaption>
          </figure>
        </div>
        <div data-aos="fade-down" class="col-lg-4 col-md-6 col-sm-12">
          <figure class="snip1360">
            <img src="../../../assets/img/news/4.png" alt="sample88" />
            <figcaption data-aos="fade-down">
              <h2>WasteWater Treatment</h2>
              <p>Unico has been proudly chosen to become the first in the region to design, build and operate the one of a kind WasteWater Treatment Plant associated with oil and gas production. Our solutions exist in two different forms; the main treatment facility which is located along the shoreline of western Port Said or through customizing and installing portable units that accommodate to client specifications in various locations.</p>
              <a href="javascript:void(0)" class="read-more" data-toggle="modal" data-target="#news2">Read More</a>
            </figcaption>
          </figure>
        </div>
        <!--<div data-aos="fade-up" class="col-lg-4 col-md-6 col-sm-12">
          <figure class="snip1360">
            <img src="../../../assets/img/news/1.jpeg" alt="sample88" />
            <figcaption data-aos="fade-down">
              <h2>Congratulations!</h2>
              <p>UNICO has just executed the biggest confined space NORM decontamination job in EGYPT . A record of 320 bbl. Special thanks to confined space team lead by Eng Mohamed Harfoosh UNICO is seen as Market leader in NORM decontamination, introducing the latest technology . UNICO promises to keep delivering too service quality to its clients</p>
              <a href="javascript:void(0)" class="read-more" data-toggle="modal" data-target="#news1">Read More</a>
            </figcaption>
          </figure>
        </div>-->
        <div data-aos="fade-down" class="col-lg-4 col-md-6 col-sm-12">
          <figure class="snip1360">
            <img src="../../../assets/img/news/2.jpeg" alt="sample88" />
            <figcaption data-aos="fade-down">
              <h2>Badr El Din "Bapetco"</h2>
              <p>Unico signed an agreement contract for two years with Bapetco providing the following Provision of OBM Cutting Treatment and Final Disposal Services</p>
              <a href="javascript:void(0)" class="read-more" data-toggle="modal" data-target="#news3">Read More</a>
            </figcaption>
          </figure>
        </div>
        <div data-aos="fade-up" class="col-lg-4 col-md-6 col-sm-12">
          <figure class="snip1360">
            <img src="../../../assets/img/news/3.jpeg" alt="sample88" />
            <figcaption data-aos="fade-down">
              <h2>Badr el Din Bapteco</h2>
              <p>unico signed a one year contract with Bapteco for the Provision of Hazardous Waste Management, and during this time Unico also signed with Bapteco a two years agreement contract on The Provision of NORM Remediation</p>
              <a href="javascript:void(0)" class="read-more" data-toggle="modal" data-target="#news4">Read More</a>
            </figcaption>
          </figure>
        </div>
      </div>

    </div>
  </div>
</section>>
<!-- Modal news1 -->
<div class="modal fade" id="news2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">WasteWater Treatment</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="../../../assets/img/news/4.png" width="100%" height="300px" alt="">
        <hr>
        <p>Unico has been proudly chosen to become the first in the region to design, build and operate the one of a kind WasteWater Treatment Plant associated with oil and gas production. Our solutions exist in two different forms; the main treatment facility which is located along the shoreline of western Port Said or through customizing and installing portable units that accommodate to client specifications in various locations.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- Modal news2 -->
<div class="modal fade" id="news1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Congratulations!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="../../../assets/img/news/1.jpeg" width="100%" height="300px" alt="">
        <hr>
        <p>UNICO has just executed the biggest confined space NORM decontamination job in EGYPT . A record of 320 bbl. Special thanks to confined space team lead by Eng Mohamed Harfoosh UNICO is seen as Market leader in NORM decontamination, introducing the latest technology . UNICO promises to keep delivering too service quality to its clients</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="news3" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Badr El Din "Bapetco"</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="../../../assets/img/news/2.jpeg" width="100%" height="300px" alt="">
        <hr>
        <p>Unico signed an agreement contract for two years with Bapetco providing the following Provision of OBM Cutting Treatment and Final Disposal Services</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="news4" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Badr el Din Bapteco</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="../../../assets/img/news/3.jpeg" width="100%" height="300px" alt="">
        <hr>
        <p>unico signed a one year contract with Bapteco for the Provision of Hazardous Waste Management, and during this time Unico also signed with Bapteco a two years agreement contract on The Provision of NORM Remediation</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="news5" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Halliburton Renew Contract</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="../../../assets/img/news/5.png" width="100%" height="300px" alt="">
        <hr>
        <p>Following years of success, the renowned Halliburton has just renewed its contract with Unico's Lifting Services. The quality of our services and efficiency of our experts has granted us such great collaborations. Our Inspection services will continue to provide the highest levels of safety and accuracy towards our inspection duties and licensing. Congratulations to Unico's very own Lifting department.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="news6" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">UNICO WWTP AND PETROBEL SIGN 2 YEAR TREATMENT CONTRACT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="https://www.huber.de/fileadmin/_processed_/2/d/csm_Schaubild_Uebersicht_Zentrale_Klaeranlage_8_RGB_klein_78f9916279.jpg" width="100%" height="300px" alt="">
        <hr>
        <p>Unico has succeeded in signing its first contract with PETROBEL to treat the water associated with the production of oil and natural gas through SSCO.  This is due to the highest levels of quality resulting from Unico’s treatment and technology used for the first time in Egypt and the Middle East; the Tri-Technology. It is considered the first real operating contract that extends for to two years starting from 18/2/2021 taking place in Unico’s WWTP in Port Said.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="news7" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">SIX YEARS OF UNICO AND KPC</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="../../../assets/img/news/khalda.jpg" width="100%" height="300px" alt="">
        <hr>
        <p>Unico NORM, one of our free zone companies has been awarded once again the NORM contract as the main contractor with KPC for the third time in six consecutive years. To many successes for UNICO’s NORM department!</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
