<section class="downhole">
  <div class="downhole-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">DOWNHOLE DRILLING TOOLS</h1>
      </div>
    </div>
  </div>
  <div class="downhole-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">DRILLING SOLUTIONS</h3>
        </div>
      </div>
      <div class="row">
        <div class="content row">
          <div class="img col-md-6 col-sm-12">
            <img data-aos="fade-left" src="../../../assets/img/drilling/downhole/Downhole1.jpg" alt="Loading...">
          </div>
          <div class="text col-md-6 col-sm-12">
            <p data-aos="fade-up">
              Downhole tools designed to harness the power of circulation across drilling, completions and P&A operations.
            </p>
            <hr>
            <p data-aos="fade-up">
              The technology offers operators reliability across a wide range of applications from sustaining safe well control pressure to recovering stuck pipe.
            </p>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">DRILLING ENHANCERS</h3>
        </div>
      </div>
      <div class="content1 row">
        <div class="text col-md-6 col-sm-12">
          <ul data-aos="fade-up">
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">DAV MX™ CircSub</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">DICV Max™</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Drift Catcher</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">DURA Drill Float™</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">HyPR™ HoleSaver™</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Mud-Shear Sub</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Pressure Test Sub (PTS) MX™</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Self-Filling Float™</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">The SFF Trigger™</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">TopJet™</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Reamers and Stabilizers</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Vibratory Tools</li>
          </ul>
        </div>
        <div class="img col-md-6 col-sm-12">
          <img data-aos="fade-down" src="../../../assets/img/drilling/downhole/Downhole2.png" alt="Loading...">
        </div>
      </div>
      <hr>
      <div class="content2 row">
        <div class="img col-md-6 col-sm-12">
          <img data-aos="fade-right" src="../../../assets/img/drilling/downhole/Downhole4.jpg" alt="Loading...">
        </div>
        <div class="text col-md-6 col-sm-12">
          <h4 data-aos="fade-up">Down Hole Power Systems</h4>
          <ul data-aos="fade-down">
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Drilling Motors</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Drilling Tools</li>
          </ul>
          <hr>
          <h4 data-aos="fade-up">Service Equipment</h4>
          <ul data-aos="fade-down">
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Hydraulic Torque Wrench</li>
          </ul>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">WELL CONSTRUCTION</h3>
        </div>
      </div>
      <div class="content3 row">
        <div class="text col-md-6 col-sm-12">
          <h4 data-aos="fade-up">Deployment Enhancement Systems</h4>
          <ul data-aos="fade-down">
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Selective Rotation Technology</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Specialty Deployment</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Single-Run Motor Systems</li>
          </ul>
          <hr>
          <h4 data-aos="fade-up">Primary Cementing Equipment</h4>
          <ul data-aos="fade-down">
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Centralizers</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Casing Accessories</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Float Equipment</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Cementing Plugs</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Multi-Stage Cementing Equipment</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Casing Guide Shoes & Reaming Shoes</li>
          </ul>
        </div>
        <div class="img col-md-6 col-sm-12">
          <img data-aos="fade-left" src="../../../assets/img/drilling/downhole/Well-Construction.jpg" alt="Loading...">
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">COMPLETION SOLUTIONS</h3>
        </div>
      </div>

      <!--
      <div class="row cards">
        <div class="col-md-3" data-aos="fade-right">
          <div class="card text-white bg-dark mb-3">
            <div class="card-header">Upper Completions</div>
            <div class="card-body">
                <ul>
                  <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Mechanically Set</li>
                  <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Inflatable Service Packers</li>
                </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3" data-aos="fade-up">
          <div class="card text-white bg-dark mb-3">
            <div class="card-header">Lower Completions</div>
            <div class="card-body">
              <ul>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Frac Plugs</li>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Toe Valves</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3" data-aos="fade-down">
          <div class="card text-white bg-dark mb-3">
            <div class="card-header">Service Tools</div>
            <div class="card-body">
              <ul>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Mechanically Set</li>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Inflatable Service Packers</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3" data-aos="fade-left">
          <div class="card text-white bg-dark mb-3">
            <div class="card-header">Drill-stem Testing</div>
            <div class="card-body">
              <ul>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">External Casing Packer (ECP)- Traditional</li>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">External Casing Packer (ECP)- Specialty</li>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">External Casing Packer (ECP)- Accessories</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    -->
      <div class="row">
        <div data-aos="fade-right" class="card-wrapper col-lg-3 col-md-6 col-sm-12">
          <div class="card one">
          <div class="card-header">
        </div>
        <div data-aos="fade-down" class="content">
          <h3>Upper Completions</h3>
          <ul>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Mechanically Set</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Inflatable Service Packers</li>
          </ul>
        </div>
        </div>
        </div>
        <div data-aos="fade-down" class="card-wrapper col-lg-3 col-md-6 col-sm-12">
          <div class="card two">
          <div class="card-header">

          </div>
          <div data-aos="fade-down" class="content">
            <h3>Lower  Completions</h3>
            <ul>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Frac Plugs</li>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Toe Valves</li>
            </ul>
          </div>
        </div>
        </div>
        <div data-aos="fade-up" class="card-wrapper col-lg-3 col-md-6 col-sm-12">
          <div class="card three">
          <div class="card-header">
          </div>
          <div data-aos="fade-down" class="content">
            <h3>Service <br> Tools</h3>
            <ul>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Mechanically Set</li>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Inflatable Service Packers</li>
            </ul>
          </div>
        </div>
        </div>
        <div data-aos="fade-left" class="card-wrapper col-lg-3 col-md-6 col-sm-12">
          <div class="card four">
          <div class="card-header">
          </div>
          <div data-aos="fade-down" class="content">
            <h3>Drill-stem Testing</h3>
            <ul>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">External Casing Packer (ECP)- Traditional</li>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">External Casing Packer (ECP)- Specialty</li>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">External Casing Packer (ECP)- Accessories</li>
            </ul>
          </div>
        </div>
        </div>
      </div>

    <hr>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">FISHING & ABANDONMENT</h3>
        </div>
      </div>
      <div class="row" style="margin-bottom:20px;">
        <div data-aos="fade-right" class="card-wrapper col-lg-3 col-md-6 col-sm-12">
          <div class="card five">
          <div class="card-header">
        </div>
        <div data-aos="fade-down" class="content">
          <h3>Thru-Tubing</h3>
          <ul>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Thru-tubing packers</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Slim hole vibratory tools</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Mechanical collar locator</li>
          </ul>
        </div>
        </div>
        </div>
        <div data-aos="fade-up" class="card-wrapper col-lg-3 col-md-6 col-sm-12">
          <div class="card six">
          <div class="card-header">

          </div>
          <div data-aos="fade-down" class="content">
            <h3>Remedial Tools</h3>
            <ul>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Wireline equipment</li>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Remedial and repair tools</li>
            </ul>
          </div>
        </div>
        </div>
        <div data-aos="fade-down" class="card-wrapper col-lg-3 col-md-6 col-sm-12">
          <div class="card seven">
          <div class="card-header">
          </div>
          <div data-aos="fade-down" class="content">
            <h3>Wellbore Clean-up</h3>
            <ul>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Inline clean-up tools</li>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Disposable clean-up tools</li>
            </ul>
          </div>
        </div>
        </div>
        <div data-aos="fade-left" class="card-wrapper col-lg-3 col-md-6 col-sm-12">
          <div class="card eight">
          <div class="card-header">
          </div>
          <div data-aos="fade-down" class="content">
            <h3>Power Swivels</h3>
            <ul>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Power swivels</li>
            </ul>
          </div>
        </div>
        </div>
        <div data-aos="fade-down" class="card-wrapper col-lg-3 col-md-6 col-sm-12">
          <div class="card nine">
          <div class="card-header">
          </div>
          <div data-aos="fade-down" class="content">
            <h3>Fishing Tools</h3>
            <ul>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">External Catch</li>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Internal Catch</li>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Junk Catchers</li>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Milling & Cutting Tools</li>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Stroking Tools</li>
              <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Accessories</li>
            </ul>
          </div>
        </div>
        </div>
      </div>
      <!--
      <div class="row cards">
        <div class="col-md-3" data-aos="fade-right">
          <div class="card text-white bg-dark mb-3">
            <div class="card-header">Thru-Tubing</div>
            <div class="card-body">
                <ul>
                  <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Thru-tubing packers</li>
                  <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Slim hole vibratory tools</li>
                  <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Mechanical collar locator</li>
                </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3" data-aos="fade-up">
          <div class="card text-white bg-dark mb-3">
            <div class="card-header">Remedial Tools</div>
            <div class="card-body">
              <ul>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Wireline equipment</li>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Remedial and repair tools</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3" data-aos="fade-down">
          <div class="card text-white bg-dark mb-3">
            <div class="card-header">Wellbore Clean-up</div>
            <div class="card-body">
              <ul>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Inline clean-up tools</li>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Disposable clean-up tools</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3" data-aos="fade-left">
          <div class="card text-white bg-dark mb-3">
            <div class="card-header">Power Swivels</div>
            <div class="card-body">
              <ul>
                <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Power swivels</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row cards two">
        <div class="col-md-3"></div>
        <div class="col-md-6" data-aos="fade-up">
          <div class="card text-white bg-dark mb-3">
            <div class="card-header">Fishing Tools</div>
            <div class="card-body">
                <ul>
                  <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">External Catch</li>
                  <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Internal Catch</li>
                  <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Junk Catchers</li>
                  <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Milling & Cutting Tools</li>
                  <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Stroking Tools</li>
                  <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Accessories</li>
                </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    -->
    </div>
  </div>
</section>
