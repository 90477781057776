<section class="norm">
  <div class="norm-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">NORMINATOR</h1>
      </div>
    </div>
  </div>
  <div class="norm-content">
    <div class="container">
      <div class="row">
        <div class="content overview row">
          <div class="text col-md-6 col-sm-12">
            <p data-aos="fade-up"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
              We pride in our efforts of research and exploration by introducing the Normirator. The Normirator is a machine used for decontamination of pipes and downhole equipment. The technique is highly dependable on the usage of sand to remove NORM.
            </p>
            <hr>
            <p data-aos="fade-down"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
              The Normirator uses abrasive blasting techniques with high pressure to clean the contaminated equipment. With its special filters it ensures that no environmental hazards will be produced during the decontamination operation. It is a closed system with high efficiency in decontamination processes.
            </p>
          </div>
          <div class="img col-md-6 col-sm-12">
            <img data-aos="fade-left" src="../../../../../assets/img/norm/normservices.jpeg" alt="NORMINATOR">
          </div>
          <div class="text col-md-12 col-sm-12">
            <p data-aos="fade-up"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
              The Normirator is prepared to shutdown at any point in case of emergency by an electric control panel.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
