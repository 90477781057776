<section class="qhse">
  <div class="qhse-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">QHSE POLICY</h1>
      </div>
    </div>
  </div>
  <div class="qhse-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">QHSE POLICY</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 left">
          <p data-aos="fade-down">
            Unico is regarded by the oil and gas industry as a standard setter in health and safety management. We strive to perform with the highest levels of commitment towards our work force, clients, and the environment. Our team is driven by detailed safety procedures and health standards, with ongoing development and adaptation to implement wellness and sustainability throughout.
          </p>
          <hr>
          <p data-aos="fade-up">
            In doing so, we find great importance in monitoring and assessing our results to forever improve our services and products to continue to satisfy and meet the requirements of our clients, while keeping all our employees safe of any health hazards, injuries, or danger presented in the work place.
          </p>
        </div>
        <div class="col-lg-6 col-md-12 right">
          <img data-aos="fade-up" src="../../../../assets/img/qhse/qhse-back1.jpg" alt="">
        </div>
        <div data-aos="fade-down" class="col-md-12 bottom">
          <p>
            Followed by a shared duty to minimize environmental impacts, it is our mission to continue to create healthy work environments that enable willingness and responsibility. We seek to bring the greenest solutions to our work places, implementing innovative tools that cater to effective waste management. Unico, will continue to lead in the quality health and safety management among all its divisions, through a trained team of experts, comprehensive management, and unmatched dedication.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
