<div class="text-center">
  <h1>Unico Profile</h1>
</div>
<div class="loader">
  <embed
    type="application/pdf"
    src="https://unico-egypt.com/assets/forms/Employee_Handbook.pdf#toolbar=0"
    width="100%"
    height="100vh" />
</div>
