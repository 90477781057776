<section class="study">
  <div class="study-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">ENVIRONMENTAL STUDIES</h1>
      </div>
    </div>
  </div>
  <div class="study-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">ENVIRONMENTAL STUDIES</h3>
        </div>
        <div class="content row">
          <div class="col-md-12 col-sm-12 left">
            <p data-aos="fade-up">
              Unico has been reconized for its pioneering role towards environmental studies in both national and international projects.
            </p>
            <p data-aos="fade-down">
             We prize our successes to a team of experts that bring knowledge and experience from a variety of backgounds.
            </p>
          </div>
        </div>

      </div>
      <hr>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">Unico's Team of Experts</h3>
        </div>
      </div>
      <!---->
      <div id="wrapper">
        <div id="container">
            <ol class="organizational-chart">
                <li>
                  <div class="site-name">
                    <div class="site"></div>
          <div class="text-holder">Eng. Khaled Farouk<span>Environmental Studies Team Leader</span></div>
                          </div>
                    <ol>
                        <li>
                            <div class="outborder">
                            <div class="chart-text">Eng. Brigade <br> Mohamed Abdel Aziz</div>
                            </div>
                            <ol>
                                <li><div class="subchart-text">Environmental consultant</div></li>
                            </ol>
                        </li>
                        <li>
                            <div class="outborder">
                            <div class="chart-text">Dr. Wahid Emam</div>
                            </div>
                            <ol>
                                <li><div class="subchart-text">Proffessor at Ein Shams University</div></li>
                                <li><div class="subchart-text" style="margin-top:-14px;">President of the Qualitative Society for the Environment</div></li>
                            </ol>
                        </li>
                        <li>
                            <div class="outborder">
                            <div class="chart-text">Eng. Nasser Zidane</div>
                            </div>
                            <ol>
                                <li><div class="subchart-text" style="margin-top:-13px;">Unico’s Executing manager for Environmental Services.</div></li>
                            </ol>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    </div>

        <div class="row">
          <hr>
          <div class="col-md-12">
            <p data-aos="fade-down">
              Unico’s team of experts is authorized by the EEAA to carry out any studies regarding the environment and has prepared an enormous amounts of studies for big national projects; most importantly:
            </p>
            <ul data-aos="fade-up">
              <li><img src="../../../../assets/img/list-icon.png" alt="">Zohr Field</li>
              <li><img src="../../../../assets/img/list-icon.png" alt="">Abu Rudeis Field (Petrobel)</li>
              <li><img src="../../../../assets/img/list-icon.png" alt="">Crude Oil Storage Tanks (ENPI)</li>
            </ul>
          </div>
        </div>
    </div>
  </div>
</section>
