import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onsite',
  templateUrl: './onsite.component.html',
  styleUrls: ['./onsite.component.css']
})
export class OnsiteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
