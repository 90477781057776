<section class="norm">
  <div class="norm-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">NORM OVERVIEW</h1>
      </div>
    </div>
  </div>
  <div class="norm-content">
    <div class="container">
      <div class="row">
        <div class="content overview row">
          <div class="text col-md-6 col-sm-12">
            <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="">
              Unico is one of the first companies in the region to tackle NORM contamination. Through vast research and exploration our areas of treatment have greatly expanded to uncover an outstanding marketplace in NORM decontamination using special technologies.
            </p>
            <hr>
            <p data-aos="fade-down"><img src="../../../../assets/img/icons/normIcon.png" alt="">
              Unico’s NORM sector is one of the few licensed companies by the ENRRA for NORM treatment in Egypt. With our experience over the years, we have proudly invented and innovated our very own technologies to handle radioactive materials of different forms present in the oil and gas industry in consideration to the NORM HSE policies of Unico.
            </p>
          </div>
          <div class="img col-md-6 col-sm-12">
            <img data-aos="fade-left" src="../../../../assets/img/norm/n1.jpg" alt="Loading...">
          </div>
        </div>
      </div>
        <hr>
        <div class="row">
          <div class="title col-md-12">
            <h3 data-aos="fade-up">NORM SERVICES</h3>
          </div>
          <div class="content row">
            <div class="text col-md-12 col-sm-12">
              <p data-aos="fade-up">
                Unico provides advanced solutions to remove any NORM conditions presented by the oil and gas industry resulting in saving time with the highest health standards and environmental standards.
              </p>
            </div>
            <div class="col-md-6 col-sm-12 ser-list text">
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Survey and Radiation Measurements.</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Confined Space NORM Decontamination (Tanks & Vessel of variant capacities).</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Down-hole Tools and Equipment NORM Decontamination.</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> ESP & SRP NORM Decontamination including the down-hole accessories.</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Miscellaneous Items Norm Decontamination (BOB - Tubular – Valves – Restrainers & Filters).</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> APIs, Lagoons and Evaporation Ponds NORM Decontamination).</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Volume Estimation and Quantity Identification.</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Radioactive Treatment, Disposal, Storage and waste Management.</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Survey Meters Calibration Certificates and TLD’s Calibrations and exposure rates Recording.</p>
            </div>
            <div class="img col-md-6 col-sm-12">
              <img data-aos="fade-left" style="height:650px;" src="../../../../assets/img/norm/n2.png" alt="Loading...">
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="title col-md-12">
            <h3 data-aos="fade-up">NORM STRATEGY & PURPOSE</h3>
          </div>
          <div class="content row">
            <div class="img col-md-6 col-sm-12">
              <img data-aos="fade-left" src="../../../../assets/img/norm/norm2.jpg" alt="Loading...">
            </div>
            <div class="col-md-6 col-sm-12 ser-list text s">
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Norm Monitoring.</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Control over NORM contaminated equipment for industry re-use and recycling.</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Environmental Protection and Management.</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Development of NORM Management solutions.</p>
              <p data-aos="fade-up"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"> Compliance Monitoring.</p>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="title col-md-12">
            <h3 data-aos="fade-up">UNICO'S NORM HISTORY</h3>
          </div>
          <div class="content row">
            <div class="main-timeline" data-aos="fade-up">
              <div class="timeline">
                <a href="javascript:void(0);" class="timeline-content">
                  <div class="timeline-year"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"></div>
                  <h3 class="title"></h3>
                  <p class="description">
                    Unico performed numerous of NORM decontamination projects for more than 20 companies in Egypt and abroad.
                  </p>
                </a>
              </div>
              <div class="timeline">
                <a href="javascript:void(0);" class="timeline-content">
                  <div class="timeline-year"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"></div>
                  <h3 class="title"></h3>
                  <p class="description">
                    Unico’s NORM team has performed over 500 confined space decontamination operations.
                  </p>
                </a>
              </div>
              <div class="timeline">
                <a href="javascript:void(0);" class="timeline-content">
                  <div class="timeline-year"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"></div>
                  <h3 class="title"></h3>
                  <p class="description">
                    Unico’s NORM sector has over hundreds of thousands of pipes for decontamination projects in addition to twenty thousand sucker rods with highly safe technologies and universal standards.
                  </p>
                </a>
              </div>
              <div class="timeline">
                <a href="javascript:void(0);" class="timeline-content">
                  <div class="timeline-year"><img src="../../../../assets/img/icons/normIcon.png" alt="norm services"></div>
                  <h3 class="title"></h3>
                  <p class="description">
                    Unico performed hundreds of NORM decontamination of miscellaneous items.
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</section>
