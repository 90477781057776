<section class="bio">
  <div class="bio-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">BIO-REMEDIATION</h1>
      </div>
    </div>
  </div>
  <div class="bio-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">BIO-REMEDIATION</h3>
        </div>
        <div class="content row">
          <div class="col-md-6 col-sm-12 left">
            <p data-aos="fade-up">
              As a leading company in the Environmental Sector in the region; Unico’s role towards the increase of Oil Base Mud Cutting was find a solution to improve the efficiency of our treatment. For the first time in Egypt, Unico Introduced the latest technology to degrade the organic pollutant in Oil Base Mud Cutting; known as Bio-Remediation.
            </p>
            <hr>
            <p>
              Accompanied by very minimal, negligible emissions to abide by the EEAA limits. This technology was researched and implemented by Unico’s R&D team and the Micro Biology professors at Alexandria University.
A branch of biotechnology utilizes the biological mechanisms inherent in bacteria to eliminate the environmental contaminants and restore the ecosystem to its original condition.
            </p>
          </div>
          <div class="col-md-6 col-sm-12 right">
              <img data-aos="fade-left" src="../../../../assets/img/environment/bio/bio-remediation.jpg" alt="">
              <span data-aos="fade-up">Bio Remediation Pits located in Burg El Arab, el Hamam</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
