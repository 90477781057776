<section class="overview">
  <div class="overview-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">LIFTING OVERVIEW</h1>
        <!--<div class="desc">
          <p data-aos="fade-down">
            UNICO Lifting Services has been providing lifting services and solutions for over two decades. We pride ourselves in offering our clients a professional service and provide wide range of high quality products, supply tools and inspection services to meet all your requirements 24 hours a day
  365 days a year. In addition our services are designed to assist our customers to improve safety, increase productivity and operational savings.
          </p>
        </div>-->
      </div>
    </div>
  </div>
  <div class="overview-content">
    <div class="container">
      <div class="title row">
        <h1 class="col-md-12">Welcome to UNICO Lifting Services</h1>
      </div>
      <div class="row content">
        <div class="col-md-6 left">
          <p>
            UNICO Lifting Services has been providing lifting services in (Inspection, Supply and Training) for over two decades. We pride ourselves in offering our clients professional services and provide a wide range of high-quality products, supply tools, inspection and training services to meet all your requirements 24 hours a day 365 days a year. In addition, our services are designed to assist our customers to improve safety, increase productivity and operational savings.
          </p>
        </div>
        <div class="col-md-6 right">
          <img src="https://i.ytimg.com/vi/JY5TkEKR7-A/maxresdefault.jpg" alt="">
        </div>
      </div>
      <hr>
      <div class="title row">
        <h1 class="col-md-12">WHY UNICO LIFTING?</h1>
      </div>
      <div class="row content">
        <p class="text-center" style="line-height: 2;">All our staff are fully trained and certified in the inspection services, Our engineers have been given specialized external and in house training. Bringing world class efficiency and qualifications to ground. All testing and inspection are carried out in strict accordance with approved standard procedures, and providing secure testing, certification, and consistent followup.</p>
        <div class="imgs row">
          <div class="col-md-3 col-sm-12">
            <figure class="snip1205">
              <img src="../../../../assets/img/lifting/overview/leea.jpeg" alt="sample42"/>
              <span>LEEA</span>
            </figure>
          </div>
          <div class="col-md-3 col-sm-12">
            <figure class="snip1205">
              <img src="../../../../assets/img/lifting/overview/nsl.jpg" alt="sample42"/>
              <span>NSL</span>
            </figure>
          </div>
          <div class="col-md-3 col-sm-12">
            <figure class="snip1205">
              <img src="../../../../assets/img/lifting/overview/asnt.jpg" alt="sample42"/>
              <span>ASNT</span>
            </figure>
          </div>
          <div class="col-md-3 col-sm-12">
            <figure class="snip1205">
              <img src="../../../../assets/img/lifting/overview/irata1.png" alt="sample42"/>
              <span>IRATA</span>
            </figure>
          </div>
          <div class="col-md-6" style="margin-top: 30px;">
            <img src="https://www.cranesandlifting.com.au/wp-content/uploads/2019/12/KitoWarranty.jpg" alt="">
          </div>
          <div class="col-md-6" style="margin-top: 30px;">
            <p>
              And we are supplier for the world's largest companies so we guarantee high products. We have a fabrication workshop and modern equipment for fabrication the wire rope slings and webbing slings. We have a professional Trainers for providing the Training Courses at our training center or our client’s premises.
            </p>
            <hr>
            <p>
              These can be reviewed and audited by the client prior to any work being carried out, Hence we can guarantee offering the following services for example:
            </p>
          </div>
          <div class="row" style="margin-top: 20px;">
            <div class="col-md-4">
              <div data-aos="fade-down" id='main2'>
                <div id='info'>
                  <div id='name'>
                    <span><img style="width: auto;height: auto;" src="https://img.icons8.com/material-outlined/20/000000/circled-dot.png"/> Certified and calibrated tools.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div data-aos="fade-down" id='main2'>
                <div id='info'>
                  <div id='name'>
                    <span><img style="width: auto;height: auto;" src="https://img.icons8.com/material-outlined/20/000000/circled-dot.png"/> Top Qualified inspectors.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div data-aos="fade-down" id='main2'>
                <div id='info'>
                  <div id='name'>
                    <span><img style="width: auto;height: auto;" src="https://img.icons8.com/material-outlined/20/000000/circled-dot.png"/> Products of Highest Quality . </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2"></div>
            <div class="col-md-4">
              <div data-aos="fade-down" id='main2'>
                <div id='info'>
                  <div id='name'>
                    <span><img style="width: auto;height: auto;" src="https://img.icons8.com/material-outlined/20/000000/circled-dot.png"/> Updated quality management system.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div data-aos="fade-down" id='main2'>
                <div id='info'>
                  <div id='name'>
                    <span><img style="width: auto;height: auto;" src="https://img.icons8.com/material-outlined/20/000000/circled-dot.png"/> professional Trainers and training programs.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
