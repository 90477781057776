import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bioremediation',
  templateUrl: './bioremediation.component.html',
  styleUrls: ['./bioremediation.component.css']
})
export class BioremediationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
