<section class="production">
  <div class="production-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">PRODUCTION<br>Products & Services</h1>
      </div>
    </div>
  </div>
  <div class="production-content">
    <div class="container">
      <div class="top row">
        <h3 data-aos="fade-up">
          Our services range from well design, Supply Artificial lift systems (ALS), installation , troubleshooting, repair and redress of all products.
        </h3>
      </div>
      <div class="content row">
        <div class="text col-md-6 col-sm-12">
          <p data-aos="fade-up">
            Unico’s production services are an ongoing success of 13 years in Egypt, the Arab region and North Africa. We are currently representing American, Canadian and European companies with proven global performance with major operating companies.
          </p>
          <hr>
          <p data-aos="fade-down">
            In addition to being manufactured and assembled as per API standards in different locations in North America and Europe to support global and regional demands and maintain highest level of quality control on raw material selection and final products compliance.
          </p>
        </div>
        <div class="img col-md-6 col-sm-12">
          <img data-aos="fade-left" src="../../../assets/img/production/img/IMAG0356.jpg" alt="Loading...">
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">PRODUCTS</h3>
          <h4 data-aos="fade-down">"All products supplied are API standard certified"</h4>
        </div>
      </div>
      <div class="content1 row">
        <div class="img col-lg-6 col-md-12 col-sm-12">
          <img data-aos="fade-down" src="../../../assets/img/production/img/IMAG1747.jpg" alt="Loading...">
        </div>
        <div class="text col-lg-6 col-md-12 col-sm-12">
          <ul>
            <li data-aos="fade-up"><img src="../../../../assets/img/gears-icon-png-11.png" alt="">
              Sucker Rod Pumping ( SRP )System
              <ul>
                <div data-aos="fade-down">
                  <li><img src="../../../assets/img/list-icon.png" alt="">Downhole Sucker Rod pumps & specialty pumps for gassy and sand conditions.</li>
                  <li><img src="../../../assets/img/list-icon.png" alt="">Rods ( Steel & Fiber glass ).</li>
                  <li><img src="../../../assets/img/list-icon.png" alt="">Well Head ( BOP , Pumping Tee , Stuffing Box ).</li>
                  <li><img src="../../../assets/img/list-icon.png" alt="">
                    Completion.
                    <ul>
                      <li><img src="../../../assets/img/dot-icon-png-5.png" alt="">TAC ( Hydraulic & Mechanical ).</li>
                      <li><img src="../../../assets/img/dot-icon-png-5.png" alt="">Setting Nipple.</li>
                      <li><img src="../../../assets/img/dot-icon-png-5.png" alt="">Insert Pump Anchor.</li>
                    </ul>
                  </li>
                </div>
                <div data-aos="fade-up">
                  <li><img src="../../../assets/img/list-icon.png" alt="">VSD , pump of Controller & GMC ™ global monitoring and control system.</li>
                  <li><img src="../../../assets/img/list-icon.png" alt="">
                    Surface Unit.
                    <ul>
                      <li><img src="../../../assets/img/dot-icon-png-5.png" alt="">Conventional Beam Pumping Unit.</li>
                      <li><img src="../../../assets/img/dot-icon-png-5.png" alt="">Linear Rod pump (LRP®) Alternative for conventional Beam pumping Unit.</li>
                    </ul>
                  </li>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <hr>
      <div class="content1 row">
        <div class="text prog col-lg-6 col-sm-12 col-sm-12">
          <ul>
            <li data-aos="fade-up"><img src="../../../../assets/img/gears-icon-png-11.png" alt="">
              Progressive Cavity Pump (PCP) System
              <ul>
                <li><img src="../../../assets/img/list-icon.png" alt="">Drive Head.</li>
                <li><img src="../../../assets/img/list-icon.png" alt="">Conventional Drive Head.</li>
                <li><img src="../../../assets/img/list-icon.png" alt="">PMM (Permanent Magnetic Motor).</li>
                <li><img src="../../../assets/img/list-icon.png" alt="">Variable Speed Drive ( VSD ) & GMC ™ .</li>
                <li><img src="../../../assets/img/list-icon.png" alt="">Well Head.</li>
                <li><img src="../../../assets/img/list-icon.png" alt="">Drive Rods.</li>
                <li><img src="../../../assets/img/list-icon.png" alt="">Completion.</li>
                <li><img src="../../../assets/img/list-icon.png" alt="">Downhole pump.</li>
              </ul>
            </li>
            <li data-aos="fade-up"><img src="../../../../assets/img/gears-icon-png-11.png" alt="">Complete Plunger Lift System</li>
            <li data-aos="fade-dwon"><img src="../../../../assets/img/gears-icon-png-11.png" alt="">
              Electrical Submersible Pump ( ESP)
              <ul>
                <li><img src="../../../assets/img/list-icon.png" alt="">VSD.</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="img col-lg-6 col-sm-12 col-sm-12">
          <img data-aos="fade-down" src="../../../assets/img/production/img/IMG_0024.JPG" alt="Loading...">
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">SERVICES</h3>
        </div>
      </div>
      <div class="content1 services row">
        <div class="img col-md-6 col-sm-12">
          <img data-aos="fade-down" src="../../../assets/img/production/img/1.JPG" alt="Loading...">
        </div>
        <div class="text col-md-6 col-sm-12">
          <ul data-aos="fade-up">
            <li><img src="../../../../assets/img/gears-icon-png-11.png" alt=""> Well Design</li>
            <li><img src="../../../../assets/img/gears-icon-png-11.png" alt="">Installation</li>
            <li><img src="../../../../assets/img/gears-icon-png-11.png" alt="">Troubleshooting</li>
            <li><img src="../../../../assets/img/gears-icon-png-11.png" alt="">Repair/ Redress</li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</section>
