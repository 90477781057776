<section class="norm">
  <div class="norm-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">NORM Safety Policy</h1>
      </div>
    </div>
  </div>
  <div class="norm-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">NORM SAFETY POLICY</h3>
        </div>
        <div class="content row">
          <div class="text col-md-6 col-sm-12">
            <p data-aos="fade-up"><img src="../../../assets/img/icons/normIcon.png" alt="">
              Unico safety policy in NORM operation on sites is constantly recorded to achieve the main concept of operation procedures and NORM handling practices. 
            </p>
            <p data-aos="fade-up"><img src="../../../assets/img/icons/normIcon.png" alt="">
              Developing strategies and safety codes for UNICO’s NORM crew to achieve the highest levels of efficiency within NORM dealing acts and safety standards especially with radioactive materials involvement. 
            </p>
            <p data-aos="fade-up"><img src="../../../assets/img/icons/normIcon.png" alt="">
              Unico performs the Decontamination operations by undertaking high safety conditions to ensure workers’ protection and prevent the spread of NORM contamination.
            </p>
            <p data-aos="fade-up"><img src="../../../assets/img/icons/normIcon.png" alt="">
              Unico Technologies concentrate on minimizing the product wastes through advanced NORM solutions techniques with an ongoing innovative initiative to always keep advancing and finding better solutions.
            </p>
            <p data-aos="fade-up"><img src="../../../assets/img/icons/normIcon.png" alt="">
              Unico’s NORM management system is created to carry out consultation and engagement of stakeholders in which all operations are done after regulatory authorities’ acceptance and approval over all techniques used. 
            </p>
            <p data-aos="fade-up"><img src="../../../assets/img/icons/normIcon.png" alt="">
              All decontamination systems manufactured by Unico are designed with significant integrated safety hazards control with effective activities involved in a well-defined safe system of work and also considering the industrial hazards and providing the optimum protection required for all our workers and the environmental protection at any client’s site.
            </p>
          </div>
          <div class="img col-md-6 col-sm-12">
            <img data-aos="fade-left" src="../../../assets/img/norm/n9.jpg" alt="Loading...">
          </div>
          <div class="col-md-12 text">
            <p data-aos="fade-up"><img src="../../../assets/img/icons/normIcon.png" alt="">
              Unico works according to the reference of “INTERNATIONAL ATOMIC ENERGY AGENCY” for Radiation Protection and the Management of Radioactive Waste in the Oil and Gas Industry Safety Reports Series No. 34, IAEA in Vienna (2003) and within “INTERNATIONAL ATOMIC ENERGY AGENCY “of Radiation Protection and Safety of Radiation Sources International Basic Safety Standards according to No. GSR Part 3 in Vienna in (2014) and 7th law of 2010 of Egyptian law affiliated to ENRRA of radioactive materials safety standards and restriction which the law stipulated that all nuclear and radiation facilities, practices, and activities in the Arab Republic of Egypt shall exclusively be restricted to peaceful purposes.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
