<section class="certificate">
  <div class="certificate-header">
    <section class="signinwrapper">
      <div class="signinpanel">
        <div class="container">
          <div class="form-welcome-wrapper row">
            <div class="col-md-8 col-sm-12">
              <img class="logo" src="../../../../assets/img/logo.jpg" width="80">
              <h1>[ Client Access ]</h1>
            </div>
            <div class="col-md-4 col-sm-12" style="border-left: 1px solid;text-align: center;">
              <div class="righ-quo">
                <span>To Open The Online System</span><br>
                <span>Kindly Click Here</span>
              </div>
              <a href="https://unico.bexel.io/auth/login" target="_blank" class="system-btn">Online System</a>
            </div>
          </div>
        </div>
      </div>
      </section>
  </div>
</section>

<div class="slider">
  <ngb-carousel #carousel>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/1.jpg" alt="Img 1">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/2.jpg" alt="Img 2">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/3.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/4.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/5.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/6.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/7.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/8.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/9.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/10.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/11.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/12.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/13.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/14.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/15.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/16.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/17.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../../assets/img/lifting/online/18.jpg" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3></h3>
          <p></p>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>

</div>
<!--
<ng-template #nochrome>
  <iframe src="https://unico.bexel.io/auth/login" width="100%" title="Online System"></iframe>
</ng-template>
-->
<!--
<section id="section-1">
    <div class="content-slider">
      <input type="radio" id="banner1" class="sec-1-input" name="banner" checked>
      <input type="radio" id="banner2" class="sec-1-input" name="banner">
      <input type="radio" id="banner3" class="sec-1-input" name="banner">
      <input type="radio" id="banner4" class="sec-1-input" name="banner">
      <div class="slider">
        <div id="top-banner-1" class="banner">
          <div class="banner-inner-wrapper">

          </div>
        </div>
        <div id="top-banner-2" class="banner">
          <div class="banner-inner-wrapper">

          </div>
        </div>
        <div id="top-banner-3" class="banner">
          <div class="banner-inner-wrapper">

          </div>
        </div>
        <div id="top-banner-4" class="banner">
          <div class="banner-inner-wrapper">


          </div>
        </div>
      </div>
      <nav>
        <div class="controls">

        </div>
      </nav>
    </div>
  </section>
-->
