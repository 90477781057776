<div class="container" class="reg">
  <div class="text-center" style="border-bottom: 0.2px solid;">
    <h1>unico's work regulations</h1>
    <h5>PREVIEW ANY OF THE FOLLOWING REGULATIONS</h5>
  </div>
  <main class="page-content">
    <div class="card" id="regbox">
      <div class="content">
        <h2 class="title">لائحة العمل الداخلية<br>Internal Work Regulations Chart</h2>
        <button class="btn" data-toggle="modal" data-target="#vacation1"><img src="../../../../assets/img/dashboard/preview-icon.png" />Preview Form</button>

      </div>
    </div>
    <!--Modal-->
    <div class="modal fade" id="vacation1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Internal Work Regulations Chart</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <embed
            type="application/pdf"
            src="https://unico-egypt.com/assets/forms/internal-reg.pdf#toolbar=0"
            width="100%"
            height="400px" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="content">
        <h2 class="title">لائحة عمل السيدات<br>Women's Work Regulations Chart</h2>
        <button class="btn" data-toggle="modal" data-target="#vacation2"><img src="../../../../assets/img/dashboard/preview-icon.png" />Preview Form</button>

      </div>
    </div>
    <!--Modal-->
    <div class="modal fade" id="vacation2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Women's Work Regulations Chart</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <embed
            type="application/pdf"
            src="https://unico-egypt.com/assets/forms/ladies_reg.pdf#toolbar=0"
            width="100%"
            height="400px" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="content">
        <h2 class="title">لائحة الجزاءات<br>Penalties Regulations Chart</h2>
        <button class="btn" data-toggle="modal" data-target="#vacation3"><img src="../../../../assets/img/dashboard/preview-icon.png" />Preview Form</button>

      </div>
    </div>
    <!--Modal-->
    <div class="modal fade" id="vacation3" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Penalties Regulations Chart</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <embed
            type="application/pdf"
            src="https://unico-egypt.com/assets/forms/penalties_reg.pdf#toolbar=0"
            width="100%"
            height="400px" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </main>

</div>
