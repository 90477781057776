import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-norminator',
  templateUrl: './norminator.component.html',
  styleUrls: ['./norminator.component.css']
})
export class NorminatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
