import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutinspection',
  templateUrl: './aboutinspection.component.html',
  styleUrls: ['./aboutinspection.component.css']
})
export class AboutinspectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
