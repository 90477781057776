<section class="solid">
  <div class="solid-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">SOLID CONTROL SERVICES</h1>
      </div>
    </div>
  </div>
  <div class="solid-content">
    <div class="container">
      <div class="content row">
        <div class="text col-md-6 col-sm-12">
          <p data-aos="fade-up">
            Our equipment and services are designed to optimise drilling fluid performance & improve drilling efficiency, Pit cleaning and mud recovery services for drilling, production, abandonment and decommissioning operations, Mud cooling systems for the effective control of drilling fluid temperatures,
          </p>
          <hr>
          <p data-aos="fade-down">
            resulting in improved safety and reduced costs on HP/HT drilling projects, Your drill cuttings managed from the point of production offshore or onshore to the point of re-use, recycling or disposal.
          </p>
        </div>
        <div class="img col-md-6 col-sm-12">
          <img data-aos="fade-left" src="../../../assets/img/drilling/solid/solid.jpg" alt="Loading...">
        </div>
      </div>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">SOLID CONTROL SERVICES</h3>
        </div>
      </div>
      <div class="content1 row">
        <div class="img col-md-6 col-sm-12">
          <img data-aos="fade-down" src="../../../assets/img/drilling/solid/solid3.jpg" alt="Loading...">
        </div>
        <div class="text col-md-6 col-sm-12">
          <ul data-aos="fade-up">
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Solids Control</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Tank and Pit Cleaning</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Mud Coolers</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Drill Cuttings Management</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">DNV Mud Skips</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">DNV Mini Containers</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
