
<div *ngIf="!dash">
  <app-navbar></app-navbar>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
  <app-footer></app-footer>
</div>
<div *ngIf="dash">
  <app-sidebar></app-sidebar>

</div>
