import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liftingcertification',
  templateUrl: './liftingcertification.component.html',
  styleUrls: ['./liftingcertification.component.css']
})
export class LiftingcertificationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
