<section class="completion">
  <div class="completion-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">COMPLETION<br>PRODUCTS & SERVICES</h1>
      </div>
    </div>
  </div>
  <div class="completion-content">
    <div class="container">
      <div class="content row">
        <div class="text col-md-6 col-sm-12">
          <p data-aos="fade-up">
            Multilateral technology and performance criterias: Flow rate from 40% to 100%. An additional sidetrack produces 40% to 100% incremental flow rate, while well construction costs are only 15% to 20% higher, Savings from 30 mln rubles, Only during construction of a multilateral well.
          </p>
          <hr>
          <p data-aos="fade-down">
            We offer an online platform for reservoir management and production optimization using unique completions and well monitoring systems as well as artificial intelligence software solutions.
          </p>
        </div>
        <div class="img col-md-6 col-sm-12">
          <img data-aos="fade-left" src="../../../assets/img/drilling/completion/competion_products.jpg" alt="Loading...">
        </div>
      </div>
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">PRODUCTS & SERVICES</h3>
        </div>
      </div>
      <div class="content1 row">
        <div class="img col-md-6 col-sm-12">
          <img data-aos="fade-down" src="../../../assets/img/drilling/completion/Completion-Products-and-Services.png" alt="Loading...">
        </div>
        <div class="text col-md-6 col-sm-12">
          <ul data-aos="fade-up">
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Drilling casing hole sections, such as conductor, surface casing, intermediate and production casing, takes 70% to 90% of costs and rig time.</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Drilling liner sections takes 8% to 15% of costs and rig time. Due to high costs at the initial stage, oil production is not very cost effective.</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Permanent Monitoring Systems.</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Distributed Pressure and Temperature Monitoring System.</li>
            <li><img src="../../../../assets/img/dot-icon-png-5.png" alt="">Intelligent Completion.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
