<section class="covid">
  <div class="covid-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">COVID-19 POLICY</h1>
      </div>
    </div>
  </div>
  <div class="covid-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">COVID-19 POLICY</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 left">
          <p data-aos="fade-up">
            UNICO's policy includes the measures we are actively taking to mitigate the spread of the Coronavirus while informing and training our team about the necessary precautions that should be taken towards their well-being and the well-being of others. . It is of great importance to respond responsibly and transparently to these health precautions. Our duty towards our team's private health and personal data is handled with high confidentiality and sensitivity.
          </p>
          <hr>
          <p data-aos="fade-down">
            This Coronavirus (COVID-19) policy is susceptible to change with the introduction of additional governmental guidelines and adaptation to the global recommendations.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>>
