<section class="norm">
    <div class="norm-header">
      <div class="text">
        <div class="title container">
          <h1 data-aos="fade-left">Rod Decontamination Machine (RDM)</h1>
        </div>
      </div>
    </div>
    <div class="norm-content">
      <div class="container">
        <div class="row">
          <div class="content overview row">
            <div class="text col-md-6 col-sm-12">
              <p data-aos="fade-up"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
                Designed by Unico engineers the RDM performs operations on Sucker Rod Pumps using its special technology to safely reach BGL readings with 100% efficiency.
              </p>
              <hr>
              <p data-aos="fade-down"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
                The RDM is unique in producing very low volumes of waste which is highly required in the Oil and Gas industry especially regarding the highly demanded target for radioactive waste lessening.
              </p>
              <hr>
              <p data-aos="fade-down"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
                Unico’s Rod Decontamination Machine features specialized filters to keep the environment safe to the workers and their surroundings.
              </p>
            </div>
            <div class="img col-md-6 col-sm-12">
              <img data-aos="fade-left" src="../../../../../assets/img/norm/n8.jpg" alt="NORMINATOR">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
