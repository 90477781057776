<!--<div class="card" *ngIf="manager">
  <div class="card-content">
    <div class="card-title">
      <span>Add Announcement</span>
    </div>

    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div class="form-group">
        <label for="exampleInputEmail1">Announcement Title</label>
        <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Add Announcement Title" [(ngModel)] = "item.title" name="title">
      </div>
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Announcement Description</label>
        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Add Announcement Description" [(ngModel)] = "item.description" name="description"></textarea>
        </div>
        <div>
        </div>
      <button type="submit" class="btn btn-primary">Publish Announcement</button>
    </form>
  </div>
</div>-->
<!--
<div>
  <ejs-richtexteditor id='apiRTE' #apiRTE [toolbarSettings]='tools' [iframeSettings]='iframe' [height]='height'>
    <ng-template #valueTemplate>
          <p><b>Key features:</b></p>
    </ng-template>
    </ejs-richtexteditor>
</div>
<a class="btn" (click)="rich()">View Rich</a>
-->
<div class="card" *ngIf="manager">
  <div class="card-content">
    <div class="card-title">
      <span>Add Announcement</span>
    </div>

    <form #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div class="form-group">
        <label for="exampleInputEmail1">Announcement Title</label>
        <input type="text" class="form-control" id="exampleInputEmail1" placeholder="Add Announcement Title" [(ngModel)] = "item.title" name="title">
      </div>
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Announcement Description</label>
        <div>
          <ejs-richtexteditor id='apiRTE' #apiRTE [toolbarSettings]='tools' [iframeSettings]='iframe' [height]='height'>
            <ng-template #valueTemplate>
                  <p><b>Add Announcement Description</b></p>
            </ng-template>
            </ejs-richtexteditor>
        </div>
      </div>
        <div>
        </div>
      <button type="submit" class="btn btn-primary">Publish Announcement</button>
    </form>
  </div>
</div>
