<section class="cen">
  <div class="cen-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">INCINERATORS</h1>
      </div>
    </div>
  </div>
  <div class="cen-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">INCINERATORS</h3>
        </div>
        <div class="text col-md-12">
          <div class="first">
            <h4 data-aos="fade-up">Oil Base Mud Cutting Incinerators:</h4>
            <p data-aos="fade-down">
              Transforms environmental hazardous pollutants to become safe elements such as CO<sub>2</sub>, H<sub>2</sub>O vapor and safe ash. Temperature up to 1200℃ which is enough to transform toxic gases to safe environmental emissions.
            </p>
          </div>
          <div class="second">

            <h4 data-aos="fade-right">Hazardeous Chemical Incinerators:</h4>

            <div class="cen-cards">
              <div class="flex row">
                <div class="col-md-4 col-sm-12">
                  <div class="card one" data-aos="fade-right">
                    <div class="header">

                    </div>

                    <div class="content" data-aos="fade-down">
                      <h3>Oil Base Mud Cutting Incinerator</h3>
                      <ul>
                        <li>
                          Location: Burg El Arab, El Hamam
                        </li>
                        <li>
                          Type of Treatment:
                          <div class="nested">
                            <img src="../../../../assets/img/icons/dot-logo.png"/><span>Contaminated Soil</span><br>
                            <img src="../../../../assets/img/icons/dot-logo.png"/><span>Sludge</span><br>
                            <img src="../../../../assets/img/icons/dot-logo.png"/><span>Oil Base Mud Cutting</span><br>
                            <img src="../../../../assets/img/icons/dot-logo.png"/><span>Water Base Mud Cutting</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="card two" data-aos="fade-left">
                    <div class="header">

                    </div>
                    <div class="content" data-aos="fade-down">
                      <h3>Oil Base Mud Cutting Incinerator</h3>
                      <ul>
                        <li>Location: Abu Rudeis, on site treatment at Petrobel in Sinai</li>
                        <li>Type of Treatment:
                          <div class="nested">
                            <img src="../../../../assets/img/icons/dot-logo.png"/><span>Contaminated Soil</span><br>
                            <img src="../../../../assets/img/icons/dot-logo.png"/><span>Sludge</span><br>
                            <img src="../../../../assets/img/icons/dot-logo.png"/><span>Oil Base Mud Cuttingpop</span><br>
                            <img src="../../../../assets/img/icons/dot-logo.png"/><span>Water Base Mud Cutting</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 col-sm-12">
                  <div class="card three" data-aos="fade-left">
                    <div class="header">

                    </div>
                    <div class="content" data-aos="fade-down">
                      <h3>Hazardous Chemical Incinerator</h3>
                      <ul>
                        <li>Location: Al Assad Cairo Alex Desert Road</li>
                        <li>Type of Treatment:
                          <div class="nested">
                            <img src="../../../../assets/img/icons/dot-logo.png"/><span>Expired Medicine</span><br>
                            <img src="../../../../assets/img/icons/dot-logo.png"/><span>Hazardous Chemicals</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
