<section class="inspect_list">
  <div class="inspect_list-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">LIST OF INSPECTION SERVICES</h1>
      </div>
    </div>
  </div>
  <div class="inspect_list-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">INSPECTION SERVICES</h3>
        </div>
      </div>
    </div>
    <div class="container">
      <hr>
      <div class="content1 row">
        <div class="col-md-12 top" data-aos="fade-down">
          <h4>
            Periodic Lifting Inspection (offshore, onshore & Remote location)
          </h4>
        </div>
        <div class="col-md-6 col-sm-12 left" data-aos="fade-right">
          <ul>
            <div data-aos="fade-up">
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Lifting Accessories inspection</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Cargo Carrying Units (CCU) inspection</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Cranes inspection</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Forklift inspection</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Lifting planning</li>
            </div>
            <div data-aos="fade-up">
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Runway beam inspection</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Lifting beam inspection</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Winch inspection</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Mobile elevating work platform (MEWP) inspection</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">ROV system inspection</li>
            </div>
            <div data-aos="fade-up">
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Derrick inspection</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Dropped object surveys</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">Rope access surveys</li>
            </div>
          </ul>
        </div>
        <div class="col-md-6 col-sm-12 right" data-aos="fade-down">
          <img src="../../../../assets/img/lifting/list/leaa.png" alt="">
        </div>
      </div>
      <hr>
      <div class="content2 row">
        <div class="col-md-12 top" data-aos="fade-down">
          <h3>
            NDT Inspection
          </h3>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div data-aos="fade-right" class="card-wrapper">
                <div class="card one">
                <div class="card-header">
              </div>
              <div data-aos="fade-down" class="content">
                <h3>MPI Magnetic Particulars Inspection</h3>
                <p>This method is suitable for the detection of surface and near surface discontinuities in magnetic
                  material, mainly ferritic steel and iron. The principle is to generate magnetic flux in the article to be
                  examined, with the flux lines running along the surface at right angles to the suspected defect.
                  Where the flux lines approach a discontinuity, they will stray out in to the air at the mouth of the
                  discontinuity.</p>
              </div>
              </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div data-aos="fade-right" class="card-wrapper">
                <div class="card two">
                <div class="card-header">
              </div>
              <div data-aos="fade-down" class="content">
                <h3>UT ultrasonic testing (Flow detection &amp; Wall Thickness)</h3>
                <p>This technique is used for the detection of internal and surface (particularly distant surface)
                  defects in sound conducting materials. The principle is in some respects similar to echo
                  sounding. A short pulse of ultrasound is generated by means of an electric charge applied to a piezo electric crystal, which vibrates for a very short period at a frequency related to the
                  thickness. The velocity at which these waves propagate is related to the Young’s Modulus for the
                  material and is characteristic of that material all of these parameters give us the situation of the
                  material displayed on the device screen then we can decide the material is accept or not.
                  </p>
              </div>
              </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div data-aos="fade-right" class="card-wrapper">
                <div class="card three">
                <div class="card-header">
              </div>
              <div data-aos="fade-down" class="content">
                <h3>RT radiographic testing</h3>
                <p>This technique is suitable for the detection of internal defects in ferrous and nonferrous metals
                  and other materials using radiation source. Material with internal voids is tested by placing the
                  subject between the source of radiation and the film. The voids show as darkened areas, where
                  more radiation has reached the film, on a clear background.
                  </p>
              </div>
              </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12"></div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div data-aos="fade-right" class="card-wrapper">
                <div class="card four">
                <div class="card-header">
              </div>
              <div data-aos="fade-down" class="content">
                <h3>DPI Dye Penetrate Inspection</h3>
                <p>This method is frequently used for the detection of surface breaking flaws in nonferromagnetic
                  Materials. The principle is cleaning the surface then apply the specific detectors to appear any
                  surface discontinuity.
                  </p>
              </div>
              </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12">
              <div data-aos="fade-right" class="card-wrapper">
                <div class="card five">
                <div class="card-header">
              </div>
              <div data-aos="fade-down" class="content">
                <h3>VT visual testing</h3>
                <p>This method is frequently used for the detection of surface discontinuity visually using special instruments.
                  </p>
              </div>
              </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12"></div>
          </div>
        </div>
      </div>
      <!--<div class="content2 row">
        <div class="col-md-6 col-sm-12 left" data-aos="fade-down">
          <img src="../../../../assets/img/lifting/list/asent.png" alt="">
        </div>
        <div class="col-md-6 col-sm-12 right">
          <h4 data-aos="fade-down">NDT Inspection</h4>
          <ul data-aos="fade-up">
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">MT magnetic testing</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">PT dye penetrate testing</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">UT ultrasonic testing</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">VT visual testing</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">RT radiographic testing</li>
          </ul>
        </div>
      </div>-->
    </div>
  </div>
</section>
