<section class="supply">
  <div class="supply-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">LIFTING SUPPLY</h1>
      </div>
    </div>
  </div>
  <div class="supply-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">SUPPLY</h3>
        </div>
      </div>
    </div>
    <div class="brief" data-aos="fade-down">
      <div class="text">
        <p class="container">
          We supply all types of lifting products (shackles, wire rope slings, chairs, chain blocks, webbing slings, hooks, swivels etc.) Partnering with the top suppliers in the field such as Crosby, Hackett and Brunton Wolf.
        </p>
      </div>
    </div>
    <div class="container">
      <div class="content row">
          <div class="col-md-12 col-sm-12 top">
            <h4 data-aos="fade-up">
              Our rigging workshop is located in the Free Zone in Alexandria, Egypt, There we also provide calibrated sets of tools such as:
            </h4>
          </div>
          <div class="col-md-6 col-sm-12 left" data-aos="fade-right">
            <ul>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> 100 ton horizontal test bed</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> 600 ton wire rope press</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> Webbing machines (manufacture webbing sling on site)</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> Fuse and tapper machines</li>
              <li><img src="../../../../assets/img/icons/dot-logo.png" alt=""> Shot blasting machine (for refurbishment of hooks, master links, etc.)</li>
            </ul>
          </div>
          <div class="col-md-6 col-sm-12 right" data-aos="fade-left">
            <img src="../../../../assets/img/lifting/supply/crosby-certificate.png" alt="">
          </div>
      </div>
      <hr>
      <div class="about_inspection-content">
        <div class="row">
          <div class="title col-md-12">
            <h3 data-aos="fade-up">SUPPLY SERVICES</h3>
          </div>
        </div>
        <div class="brief" data-aos="fade-down">
          <div class="text">
            <p class="container">
              It is necessary to inspect any lifting equipment frequently to give our clients their appropriate certification to ensure and maintain safe lifting operations.
            </p>
          </div>
        </div>
        <hr>
        <div class="content row">
          <div class="col-md-6 col-sm-12 left" data-aos="fade-right">
            <h2>Testing, Repair and Refurbishment</h2>
            <p>
              Lifting Equipment requires constant and thorough examination every six and 12 months in accordance with the examination scheme.

A 100 ton test bed is available to test all types of slings and chains. We have the potential to repair all types of slings and refurbish snatch blocks and chain blocks with a quick turnaround. Every item of equipment supplied is tested or repaired and dispatched with an individual, fully traceable test certificate detailing the exact working load limit and proof load applicable to the item.
            </p>
          </div>
          <div class="col-md-6 col-sm-12 right" data-aos="fade-left">
            <img src="../../../../assets/img/lifting/about/Lifting-test.png" alt="">
          </div>
        </div>
        <hr>
        <div class="content1 row">
          <div class="col-md-6 col-sm-12 left" data-aos="fade-up">
            <img src="../../../../assets/img/lifting/about/hire.jpg" alt="">
          </div>
          <div class="col-md-6 col-sm-12 right" data-aos="fade-down">
            <h2>Equipment Hire</h2>
            <p>
              Hiring lifting equipment is the solution for short-term period that could accomplish the demand of a single item, Based on the client’s needs for cost control.
            </p>
            <hr>
            <p>
              We offer Water bags and Telemetric Load cells, providing specialist proof load testing solutions for lifting equipment and structures. Our core commitment is to provide Load testing products and services to the highest standards for our inspector usage and for hiring.
            </p>
          </div>
        </div>
      </div>
      <hr>
      <div class="tools row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">SUPPLY PRODUCTS</h3>
        </div>
        <div class="col-md-12">
          <div class="tools-img row">
            <div class="col-md-4" data-aos="fade-right">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/supply/crosby-hook-latches.jpg" alt="img17"/>
                        <figcaption>
                          <h2>CROSBY HOKE <span>LATCHES</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" data-aos="fade-down">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/supply/crosby-lifting-clamps.jpg" alt="img17"/>
                        <figcaption>
                          <h2>CROSBY LIFTING <span>CLAMPS</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" data-aos="fade-left">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/supply/CROSBY-SLING-LINK.jpg" alt="img17"/>
                        <figcaption>
                          <h2>CROSBY SLING <span>LINK</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
            <!---->
            <div class="col-md-4" data-aos="fade-right">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/supply/tool1.jpg" alt="img17"/>
                        <figcaption>
                          <h2>WIRE ROPE <span>SLINGS</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" data-aos="fade-left">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/supply/tool4.jpg" alt="img17"/>
                        <figcaption>
                          <h2>WEBBING <span>SLINGS</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" data-aos="fade-left">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/supply/crosby-lifting-swivels.jpg" alt="img17"/>
                        <figcaption>
                          <h2>CROSBY LIFTING <span>SWIVELS</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" data-aos="fade-left">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/supply/Grade 10 Lifting Chain.jpg" alt="img17"/>
                        <figcaption>
                          <h2>LIFTING <span>CHAIN</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" data-aos="fade-left">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/supply/Manual Hoisting.jpg" alt="img17"/>
                        <figcaption>
                          <h2>MANUAL <span>HOISTING</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" data-aos="fade-left">
              <div class="card ">
                <div class="card-image waves-effect waves-block waves-light">
                   <div class="grid ">
                      <figure class="effect-hera">
                        <img src="../../../../assets/img/lifting/supply/Snatch Blocks (Yoke).jpg" alt="img17"/>
                        <figcaption>
                          <h2>SNATCH <span>BLOCKS (Yoke)</span></h2>
                        </figcaption>
                      </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
