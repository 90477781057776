<section class="certificate">
  <div class="certificate-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">CERTIFICATIONS</h1>
      </div>
    </div>
  </div>
  <div class="certificate-content">
    <div class="container">
      <div class="row">
        <div class="title col-md-12">
          <h3 data-aos="fade-up">UNICO CERTIFICATIONS</h3>
        </div>
        <div class="text col-md-12">
          <p data-aos="fade-down">
            We use our expertise as a driver for continued improvement. Our certified processes underpin the work the business undertakes to ensure our products and services meet or exceed the needs and expectations of our customers. Our quality system covers all activities that impact the services we offer to our customers and is fully embedded into our overall business management systems.
          </p>
        </div>
      </div>
      <div class="row middle">
        <div data-aos="fade-right" class="col-lg-4 col-md-6 col-sm-12">
          <div class="content 1"><a class="close1" href="#"></a>
            <div class="foto"><img src="../../../assets/img/qhse/certificates/iso45001.png" alt="" />
              <div class="shadow"></div>
            </div>
            <p data-aos="fade-down">ISO 45001 Certificate</p>
          </div>
        </div>

        <div data-aos="fade-down" class="col-lg-4 col-md-6 col-sm-12">
          <div class="content 1"><a class="close1" href="#"></a>
            <div class="foto"><img src="../../../assets/img/qhse/certificates/iso9001.jpg" alt="" />
              <div class="shadow"></div>
            </div>
            <p data-aos="fade-down">ISO 9001 Certificate</p>
          </div>
        </div>

        <div data-aos="fade-left" class="col-lg-4 col-md-6 col-sm-12">
          <div class="content 1"><a class="close1" href="#"></a>
            <div class="foto"><img src="../../../assets/img/qhse/certificates/iso14001.png" alt="" />
              <div class="shadow"></div>
            </div>
            <p data-aos="fade-down">ISO 14001 Certificate</p>
          </div>
        </div>

        <div data-aos="fade-right" class="col-lg-4 col-md-6 col-sm-12">
          <div class="content 1"><a class="close1" href="#"></a>
            <div class="foto"><img src="../../../assets/img/qhse/certificates/leaa-cert-membership.png" alt="" />
              <div class="shadow"></div>
            </div>
            <p data-aos="fade-down">LEAA Certificate of Membership</p>
          </div>
        </div>

        <div data-aos="fade-up" class="col-lg-4 col-md-6 col-sm-12">
          <div class="content 1"><a class="close1" href="#"></a>
            <div class="foto"><img src="../../../assets/img/qhse/certificates/LEEA-TRAINING.jpg" alt="" />
              <div class="shadow"></div>
            </div>
            <p data-aos="fade-down">LEAA Training Certificate</p>
          </div>
        </div>

        <div data-aos="fade-left" class="col-lg-4 col-md-6 col-sm-12">
          <div class="content 1"><a class="close1" href="#"></a>
            <div class="foto"><img src="../../../assets/img/qhse/certificates/asent.png" alt="" />
              <div class="shadow"></div>
            </div>
            <p data-aos="fade-down">ASNT Certification</p>
          </div>
        </div>
        <div class="col-lg-1 col-md-12 col-sm-12"></div>
        <div data-aos="fade-down" class="col-lg-6 col-md-12 col-sm-12">
          <div class="content 1"><a class="close1" href="#"></a>
            <div class="foto"><img src="../../../assets/img/qhse/certificates/crosby.png" alt="" />
              <div class="shadow"></div>
            </div>
            <p data-aos="fade-down">Crosby <br> Authorization</p>
          </div>
        </div>
        <div data-aos="fade-down" class="col-lg-4 col-md-12 col-sm-12">
          <div class="content 1"><a class="close1" href="#"></a>
            <div class="foto"><img src="../../../assets/img/qhse/certificates/n9.jpg" alt="" />
              <div class="shadow"></div>
            </div>
            <p data-aos="fade-down">ENRRA <br> LICENCE</p>
          </div>
        </div>
        <div class="col-lg-1 col-md-12 col-sm-12"></div>

      </div>
    </div>
  </div>
</section>
