import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-incenerator',
  templateUrl: './incenerator.component.html',
  styleUrls: ['./incenerator.component.css']
})
export class InceneratorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
