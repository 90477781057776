<div class="slider">
  <ngb-carousel #carousel>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../assets/img/h3.png" alt="Img 1">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3>Oil & Gas Extraction Wastewater Management</h3>
          <p>UNICO’s leading environmental role bringing the latest all-inclusive effluent treatment solutions to life; witness our Tri-Technology based facility in Port Said and our one-of-a-kind customizable portable units.</p>
          <button class="btn btn-light seemore"><a routerLink="/wastewatertreatment">See More</a></button>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../assets/img/lifting/SPEC-CORE-LFT-05.png" alt="Img 2">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3>UNICO’s Lifting Services</h3>
          <p>Providing a history of unmatched lifting inspection services and supply. Our team strives to provide meticulous and detailed reports ensuring the highest safety measures and precise licensing.</p>
          <button class="btn btn-light seemore"><a routerLink="/lifting/inspection_list">See More</a></button>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="img-block">
        <img src="../../../assets/img/h5.png" alt="Img 3">
      </div>
      <div class="carousel-caption">
        <div class="cpation-content container">
          <h3>QHSE</h3>
          <p>Efficiency accompanied by the safety of our environment. UNICO’s policies develop day by day adapting to the local and international status. </p>
          <button class="btn btn-light seemore"><a routerLink="/qhse/qhse-policy">See More</a></button>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>

</div>
<!--end header-->
<section class="about-h">
  <div class="container">
    <div class="row">
      <h3 data-aos="fade-up" class="col-md-12">ABOUT UNICO</h3>
      <div data-aos="fade-down" class="col-lg-4 col-md-12 col-sm-12 left">
        <img src="../../../assets/img/services.jpg" alt="Loading...">
      </div>
      <div class="col-lg-8 col-md-12 col-sm-12 right">
        <div class="icon"></div>
        <div class="text" data-aos="zoom-in-up">
          <p>
            <b>United Oil Services (UNICO Group)</b> is an oil field company established in 1993, as a stock-holding free zone company, our facilities are located all along Egypt ranging from the Mediterranean coast to the Red Sea with our Head Quarters centered in Maadi, Cairo.
          </p>
          <p>
            <b>Our Mission</b> is to provide quality services to the upstream oil and gas industry in accordance with universal environmental and safety standards. UNICO is a registered EGPC vendor and prides in its efforts of achieving renowned certifications of performance, training, and supply. Our consistency, efficiency and experience have marked a milestone of working with leading national and international entities. Since its establishment UNICO’s spectrum of services has been a growing journey bringing to the oil and gas industry a range of solutions.
          </p>
        </div>
        <div class="bot">
          <button class="btn btn-outline-dark" routerLink="/about">LEARN MORE</button>
          <button class="btn btn-dark" routerLink="/contact">CONTACT US</button>
        </div>
      </div>
    </div>
  </div>
</section>
<!--end about-->
<section class="bf">
  <div class="container">
    <div class="row">
      <div data-aos="fade-up" class="title col-md-12">
        <span>WHAT WE DO</span>
      </div>
      <div class="icons col-md-12">
        <div class="row">
          <div data-aos="fade-up-right" class="col-md-4">
            <div class="row">
              <div class="img col-md-12">
                <img src="https://img.icons8.com/windows/100/000000/recycled-water.png" />
              </div>
              <div class="sname col-md-12">
                <h4>ENVIRONMENTAL</h4>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" class="col-md-4">

            <div class="row">
              <div class="img col-md-12">
                <img src="../../../assets/img/icons/homeicons/norm.png"/>
              </div>
              <div class="sname col-md-12">
                <h4>NORM</h4>
              </div>
            </div>
          </div>
          <div data-aos="fade-up-left" class="col-md-4">
            <div class="row">
              <div class="img col-md-12">
                <img src="../../../assets/img/icons/homeicons/lifting.png"/>
              </div>
              <div class="sname col-md-12">
                <h4>LIFTING</h4>
              </div>
            </div>
          </div>

          <div data-aos="fade-up-right" class="col-md-4">
            <div class="row">
              <div class="img col-md-12">
                <img src="../../../assets/img/icons/homeicons/drilling.png"/>
              </div>
              <div class="sname col-md-12">
                <h4>DRILLING</h4>
              </div>
            </div>
          </div>
          <div data-aos="fade-down" class="col-md-4">
            <div class="row">
              <div class="img col-md-12">
                <img src="../../../assets/img/icons/homeicons/production.png"/>
              </div>
              <div class="sname col-md-12">
                <h4>PRODUCTION</h4>
              </div>
            </div>
          </div>
          <div data-aos="fade-up-left" class="col-md-4">
            <div class="row">
              <div class="img col-md-12">
                <img src="../../../assets/img/icons/homeicons/other.png"/>
              </div>
              <div class="sname col-md-12">
                <h4>OTHERS SERVICES</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--end brief-->
<section class="ser row">
  <div class=" left col-lg-4 col-md-12 col-sm-12">
    <div class="content">
      <div class="container">
        <h4 data-aos="fade-up">OUR</h4>
        <h3 data-aos="fade-down">SERVICES</h3>
        <p data-aos="fade-down">
          Our services promise to provide the best solutions in essential oil and gas sectors. From leading technologies to trained experts to supplying top products worldwide; our services guarantee consistency, quality and safety
        </p>
      </div>
    </div>
  </div>
  <div class="right col-lg-8 col-md-12 col-sm-12">
    <div class="content">
      <div class="all-cards">
        <div class="container">
          <div class="row">
            <div data-aos="fade-down-right" class="col-lg-4 col-md-6">
              <div class="main">
                <div class="service one">
                  <div class="service-logo">
                    <img src="https://img.icons8.com/windows/50/000000/recycled-water.png">
                  </div>
                  <h4>ENVIRONMENTAL</h4>
                  <p>Unico's environmental department brings solutions to prevent many hazards presented in the oil and gas community. Aiming to preserve and protect the environment we continue to explore the many dimensions of environmental awareness and the most suitable implementation processes</p>
                </div>
              </div>
            </div>
            <div data-aos="fade-down-right" class="col-lg-4 col-md-6">
              <div class="main">
                <div class="service two">
                  <div class="service-logo">
                    <img src="https://img.icons8.com/ios/50/000000/radio-active.png">
                  </div>
                  <h4>NORM</h4>
                  <p>With pilot technologies, on ground, work ethic and maximum safety measures; Unico's NORM department has been an ongoing success over the years.</p>
                </div>
              </div>
            </div>
            <div data-aos="fade-down-right" class="col-lg-4 col-md-6">
              <div class="main">
                <div class="service three">
                  <div class="service-logo">
                    <img src="https://img.icons8.com/ios/50/000000/crane-hook.png">
                  </div>
                  <h4>LIFTING</h4>
                  <p>Unico's lifting department provides an umbrella of services catering to the oil and gas industry:<br>
                    -Lifting Supply<br>
                    -Lifting Inspection<br>
                    -Lifting Training</p>
                </div>
              </div>
            </div>
            <div data-aos="fade-down-right" class="col-lg-4 col-md-6 spec">
              <div class="main">
                <div class="service four">
                  <div class="service-logo">
                    <img src="https://img.icons8.com/ios/50/000000/drilled-well.png">
                  </div>
                  <h4>DRILLING</h4>
                  <p>Collaborating with top certified providers worldwide, Unico's drilling department guarantees to supply a wide range of products followed by complete follow up and maintenance.</p>
                </div>
              </div>
            </div>
            <div data-aos="fade-down-right" class="col-lg-4 col-md-6 spec">
              <div class="main">
                <div class="service five">
                  <div class="service-logo">
                    <img src="https://img.icons8.com/ios/50/000000/oil-pump-jack.png">
                  </div>
                  <h4>PRODUCTION</h4>
                  <p>Our services range from well design, supply of artificial lift systems (ALS), installation , troubleshooting, repair and redress of all products</p>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<!--end services-->
<section class="clearfix">
  <div class="team">
  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#1A2628" fill-opacity="1" d="M0,192L80,176C160,160,320,128,480,128C640,128,800,160,960,154.7C1120,149,1280,107,1360,85.3L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>

    <div class="container">
      <div class="title">
        <h3 data-aos="fade-up">BOARD MEMBERS</h3>
      </div>
      <div class="row">
        <div class="col-md-3 col-sm-12">
          <article class="team-member">
            <div class="inner-box">
              <figure class="image-box wow " data-wow-delay="0ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: bounceInUp;">
                <a>
                  <img width="274" height="480" src="../../../assets/img/engTark.png" class="img-responsive wp-post-image" alt=""  srcset="../../../assets/img/engTark.png 274w, ../../../assets/img/engTark.png 171w" sizes="(max-width: 274px) 100vw, 274px">
                </a>
              </figure>
              <div class="lower-part">
                <div class="bg-layer"></div>
                <div class="left-curve"></div>
                <div class="right-curve"></div>
                <div class="caption-layer">
                  <div class="upper">
                    <h3>Tarek el Kolaly</h3>
                    <div class="category">
                      Managing Director
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div class="col-md-3 col-sm-12">
          <article class="team-member">
            <div class="inner-box">
              <figure class="image-box wow" data-wow-delay="0ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: bounceInUp;">
                <a>
                  <img width="274" height="480" src="../../../assets/img/engKhaled.png" class="img-responsive wp-post-image" alt="" srcset="../../../assets/img/engKhaled.png 274w, ../../../assets/img/engKhaled.png 171w" sizes="(max-width: 274px) 100vw, 274px">
                </a>
              </figure>
              <div class="lower-part">
                <div class="bg-layer"></div>
                <div class="left-curve"></div>
                <div class="right-curve"></div>
                <div class="caption-layer">
                  <div class="upper">
                    <h3>Khaled Farouk</h3>
                    <div class="category">
                      Chairman
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div class="col-md-3 col-sm-12">
          <article class="team-member">
            <div class="inner-box">
              <figure class="image-box wow" data-wow-delay="0ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: bounceInUp;">
                <a>
                  <img style="height: 535px;margin-top: -55px;width: 230px;" width="274" height="480" src="../../../assets/img/hani.png" class="img-responsive wp-post-image" alt=""  srcset="../../../assets/img/hani.png 274w, ../../../assets/img/hani.png.png 171w" sizes="(max-width: 274px) 100vw, 274px">
                </a>
              </figure>
              <div class="lower-part">
                <div class="bg-layer"></div>
                <div class="left-curve"></div>
                <div class="right-curve"></div>
                <div class="caption-layer">
                  <div class="upper">
                    <h3>Hani Zaki</h3>
                    <div class="category">
                      CEO
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        <div class="col-md-3 col-sm-12">
          <article class="team-member">
            <div class="inner-box">
              <figure class="image-box wow" data-wow-delay="0ms" data-wow-duration="1500ms" style="visibility: visible; animation-duration: 1500ms; animation-delay: 0ms; animation-name: bounceInUp;">
                <a>
                  <img width="274" height="480" src="../../../assets/img/engAhmed.png" class="img-responsive wp-post-image" alt=""  srcset="../../../assets/img/engAhmed.png 274w, ../../../assets/img/engAhmed.png.png 171w" sizes="(max-width: 274px) 100vw, 274px">
                </a>
              </figure>
              <div class="lower-part">
                <div class="bg-layer"></div>
                <div class="left-curve"></div>
                <div class="right-curve"></div>
                <div class="caption-layer">
                  <div class="upper">
                    <h3>Ahmed Tarek</h3>
                    <div class="category">
                      General Manager
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
        
      </div>
    </div>
  </div>

</section>
<!--end team-->

<section class="client">
  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#1A2628" fill-opacity="1" d="M0,0L80,10.7C160,21,320,43,480,42.7C640,43,800,21,960,37.3C1120,53,1280,107,1360,133.3L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
  <div class="container">
    <div data-aos="fade-up" class="title">
      <h3>OUR CLIENTS</h3>
    </div>
    <div data-aos="fade-down" class="row car">

      <carousel dots="true" autoplay="true" autoplayInterval="2000" loop cellsToShow="5" cellsToScroll="1" pauseOnHover="true">
        <div class="carousel-cell">
            <img src="../../../assets/img/clients/1.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/10.jpg">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/11.jpg">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/12.jpg">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/13.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/14.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/15.jpg">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/16.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/17.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/18.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/19.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/2.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/20.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/3.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/4.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/5.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/6.jpg">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/7.png">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/8.jpg">
        </div>
        <div class="carousel-cell">
          <img src="../../../assets/img/clients/9.jpg">
        </div>
      </carousel>

    </div>
  </div>
</section>
<hr>
<hr>
<!--end clients-->
<section class="map">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12">
        <h3 data-aos="fade-down">LOCATIONS</h3>
        <div class="text">

          <div class="img-map">
            <img data-aos="fade-down" class="pin1" src="../../../assets/img/icons/map-marker.gif"/>
            <div class="pin1_text">
              <div class="card text-center">
                <div class="card-body">
                  <h4 class="card-title">Burg El Arab Site</h4>
                </div>
              </div>
            </div>
            <img data-aos="fade-down" class="pin2" src="../../../assets/img/icons/map-marker.gif"/>
            <div class="pin2_text">
              <div class="card text-center">
                <div class="card-body">
                  <h4 class="card-title">Al-Assad Site</h4>
                </div>
              </div>
            </div>
            <img data-aos="fade-down" class="pin3" src="../../../assets/img/icons/map-marker.gif"/>
            <div class="pin3_text">
              <div class="card text-center">
                <div class="card-body">
                  <h4 class="card-title">Abu Rudeis</h4>
                </div>
              </div>
            </div>
            <img data-aos="fade-down" class="pin4" src="../../../assets/img/icons/map-marker.gif"/>
            <div class="pin4_text">
              <div class="card text-center">
                <div class="card-body">
                  <h4 class="card-title">Amreya Office</h4>
                </div>
              </div>
            </div>
            <img data-aos="fade-down" class="pin5" src="../../../assets/img/icons/map-marker.gif"/>
            <div class="pin5_text">
              <div class="card text-center">
                <div class="card-body">
                  <h4 class="card-title">Head Office in Cairo</h4>
                </div>
              </div>
            </div>
            <!---->
            <img data-aos="fade-down" class="pin6" src="../../../assets/img/icons/map-marker.gif"/>
            <div class="pin6_text">
              <div class="card text-center">
                <div class="card-body">
                  <h4 class="card-title">Port Said</h4>
                </div>
              </div>
            </div>
            <img data-aos="fade-down" class="pin7" src="../../../assets/img/icons/map-marker.gif"/>
            <div class="pin7_text">
              <div class="card text-center">
                <div class="card-body">
                  <h4 class="card-title">Khalda Apache</h4>
                </div>
              </div>
            </div>
            <!---->
            <img class="img" src="../../../assets/img/map-shutterstock.png" alt="">
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-12 txt">
        <div data-aos="fade-up">
          <div class="head">
            <h4>Head Office in Cairo</h4>
            <span>Building 54 A, 6th Sector Zahraa El Maadi, Cairo Egypt.</span>
          </div>
          <div class="head">
            <h4>Amreya Office</h4>
            <span>Road. 9 Free Zone – El Amreya, Alexandria.</span>
          </div>
          <div class="head">
            <h4>Abu Rudeis Site</h4>
            <span>On site treatment at Petrobel in Sinai.</span>
          </div>
          <div class="head">
            <h4>Burg El Arab Site</h4>
            <span>Matroh governorate – El Hammam – 4.5 Km.</span>
          </div>
          <div class="head">
            <h4>Al-Assad Site</h4>
            <span>Alexandria Cairo desert road km 59 behind El Assad resort.</span>
          </div>
          <!---->
          <div class="head">
            <h4>Port Said</h4>
            <span>Al Manasrah, Port Said Governorate</span>
          </div>
          <div class="head">
            <h4>Khalda Apache</h4>
            <span>Matrouh Governorate</span>
          </div>
          <!---->
        </div>
      </div>
    </div>
  </div>
</section>

