import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unicoprofile',
  templateUrl: './unicoprofile.component.html',
  styleUrls: ['./unicoprofile.component.css']
})
export class UnicoprofileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
