<div class="row">
  <div class="col-md-12">
    <h1 class="text-center">Events & Photos</h1>
  </div>
  <div class="col-md-4 col-sm-12">
    <div class="feature-card" style="background-image: url(../../../../assets/img/about/about.jpg);">
      <div class="feature-card-tilte">
        <h3>
          Managers
        </h3>
      </div>
      <div class="feature-card-deatails">
        <i class="fa fa-bank"></i>
        <h4>Managers</h4>
        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit</p>
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#event1">Read More</button>
      </div>
    </div>
  </div>
    <!-- Modal -->
  <div class="modal fade" id="event1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Managers</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <img src="../../../../assets/img/about/about.jpg" width="100%">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="col-md-4 col-sm-12">
    <div class="feature-card" style="background-image: url(../../../../assets/img/h5.png);">
      <div class="feature-card-tilte">
        <h3>
          Event Name
        </h3>
      </div>
      <div class="feature-card-deatails">
        <i class="fa fa-bank"></i>
        <h4>Event Name</h4>
        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit</p>
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#event2">Read More</button>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="event2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Event Name</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body row">
          <div class="col-md-4">
            <img src="../../../../assets/img/about/about.jpg" width="100%">
          </div>
          <div class="col-md-4">
            <img src="../../../../assets/img/h5.png" width="100%">
          </div>
          <div class="col-md-4">
            <img src="../../../../assets/img/about/about.jpg" width="100%">
          </div>
          <div class="col-md-4">
            <img src="../../../../assets/img/h5.png" width="100%">
          </div>
          <div class="col-md-4">
            <img src="../../../../assets/img/about/about.jpg" width="100%">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="col-md-4 col-sm-12">
    <div class="feature-card" style="background-image: url(../../../../assets/img/about/about.jpg);">
      <div class="feature-card-tilte">
        <h3>
          Banking
        </h3>
      </div>
      <div class="feature-card-deatails">
        <i class="fa fa-bank"></i>
        <h4>Banking</h4>
        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit</p>
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#event3">Read More</button>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="event3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <img src="../../../../assets/img/about/about.jpg" width="100%">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="col-md-4 col-sm-12">
    <div class="feature-card" style="background-image: url(../../../../assets/img/h5.png);">
      <div class="feature-card-tilte">
        <h3>
          Banking
        </h3>
      </div>
      <div class="feature-card-deatails">
        <i class="fa fa-bank"></i>
        <h4>Banking</h4>
        <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit</p>
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#event4">Read More</button>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="event4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <img src="../../../../assets/img/about/about.jpg" width="100%">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</div>
