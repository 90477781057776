<section class="norm">
    <div class="norm-header">
      <div class="text">
        <div class="title container">
          <h1 data-aos="fade-left">Handling and Dismantling</h1>
        </div>
      </div>
    </div>
    <div class="norm-content">
      <div class="container">
        <div class="row">
          <div class="content overview row">
            <div class="text col-md-6 col-sm-12">
              <p data-aos="fade-up"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
                Unico provides dismantling services for ESP special tools before decontamination under the standards of REDA Schlumberger. A trained crew of experts is in charge of dismantling ESP pumps before handling.
              </p>
              <hr>
              <p data-aos="fade-down"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
                Unico NORM provides dismantling services of SRP pump operations under the API recommended practices 11AR.
              </p>
            </div>
            <div class="img col-md-6 col-sm-12">
              <img data-aos="fade-left" src="../../../../../assets/img/norm/n4.jpg" alt="Loading...">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="content row">
            <div class="text col-md-12 col-sm-12">
              <p data-aos="fade-up">
                Our staff will carry out all operations with greatest respect to health, safety and the environment:
              </p>
            </div>
            <div class="col-md-12 col-sm-12 ser-list text">
              <p data-aos="fade-down"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
                Unico receives the company materials at our licensed decontamination area in addition to currently delivering the Mobile SRP dismantling unit to the client sites and fields.
              </p>
              <p data-aos="fade-down"><img src="../../../../../assets/img/icons/normIcon.png" alt="">
                Inspection of pumps and issuing an inspection report which includes:
              </p>
              <div class="row">
                <div data-aos="fade-down" id='main'>
                  <div id='info'>
                    <div id='name'>
                      <span>Well ID </span>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-down" id='main'>
                  <div id='info'>
                    <div id='name'>
                      <span>Pump Type </span>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-down" id='main'>
                  <div id='info'>
                    <div id='name'>
                      <span>Pump Size </span>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-down" id='main'>
                  <div id='info'>
                    <div id='name'>
                      <span>Pump Component Specs </span>
                    </div>
                  </div>
                </div>
                <div data-aos="fade-down" id='main'>
                  <div id='info'>
                    <div id='name'>
                      <span>Parts No </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="title col-md-12">
            <h3 data-aos="fade-up">OTHER TECHNIQUES</h3>
          </div>
        </div>
        <div class="row">
          <div data-aos="fade-down" id='main2'>
            <div id='info'>
              <div id='name'>
                <span>Dustless Technique </span>
              </div>
            </div>
          </div>
          <div data-aos="fade-down" id='main2'>
            <div id='info'>
              <div id='name'>
                <span>Dust Free </span>
              </div>
            </div>
          </div>
          <div data-aos="fade-down" id='main2'>
            <div id='info'>
              <div id='name'>
                <span>Laser Jet </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
