<div class="text-center">
  <h1>important forms</h1>
  <h5>Preview or Print Out Any of the Following Forms</h5>
</div>
<main class="page-content">
  <div class="card" id="formbox">
    <div class="content">
      <h2 class="title">طلب اجازة <br> Vacation Form</h2>
      <button class="btn" data-toggle="modal" data-target="#vacation"><img src="../../../../assets/img/dashboard/preview-icon.png" />Preview Form</button>
      <button class="btn" (click)="printPdf('talab_agaza.pdf')"><img src="https://img.icons8.com/small/16/ffffff/print.png"/>Print Form</button>

    </div>
  </div>
  <!--Modal-->
  <div class="modal fade" id="vacation" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">طلب اجازة</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <embed
          type="application/pdf"
          src="https://unico-egypt.com/assets/forms/talab_agaza.pdf#toolbar=0"
          width="100%"
          height="400px" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="content">
      <h2 class="title">طلب إذن <br> Permission Form</h2>
      <button class="btn" data-toggle="modal" data-target="#vacation1"><img src="../../../../assets/img/dashboard/preview-icon.png" />Preview Form</button>
      <button class="btn" (click)="printPdf('talab_ezn.pdf')"><img src="https://img.icons8.com/small/16/ffffff/print.png"/>Print Form</button>
    </div>
  </div>
  <!--Modal-->
  <div class="modal fade" id="vacation1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">طلب إذن</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <embed
          type="application/pdf"
          src="https://unico-egypt.com/assets/forms/talab_ezn.pdf#toolbar=0"
          width="100%"
          height="400px" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="content">
      <h2 class="title">طلب استقالة <br> Resignation Form</h2>
      <button class="btn" data-toggle="modal" data-target="#vacation2"><img src="../../../../assets/img/dashboard/preview-icon.png" />Preview Form</button>
      <button class="btn" (click)="printPdf('estekala.pdf')"><img src="https://img.icons8.com/small/16/ffffff/print.png"/>Print Form</button>
    </div>
  </div>
  <!--Modal-->
  <div class="modal fade" id="vacation2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">طلب استقالة</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <embed
          type="application/pdf"
          src="https://unico-egypt.com/assets/forms/estekala.pdf#toolbar=0"
          width="100%"
          height="400px" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="content">
      <h2 class="title">نموذج مأموريات <br> Errands Form</h2>
      <button class="btn" data-toggle="modal" data-target="#vacation3"><img src="../../../../assets/img/dashboard/preview-icon.png" />Preview Form</button>
      <button class="btn" (click)="printPdf('nmozg_mamoryat.pdf')"><img src="https://img.icons8.com/small/16/ffffff/print.png"/>Print Form</button>
    </div>
  </div>
  <!--Modal-->
  <div class="modal fade" id="vacation3" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">نموذج مأموريات</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <embed
          type="application/pdf"
          src="https://unico-egypt.com/assets/forms/nmozg_mamoryat.pdf#toolbar=0"
          width="100%"
          height="400px" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="content">
      <h2 class="title">إقرار بالمخالصة <br> Quittance Form</h2>
      <button class="btn" data-toggle="modal" data-target="#vacation4"><img src="../../../../assets/img/dashboard/preview-icon.png" />Preview Form</button>
      <button class="btn" (click)="printPdf('ekrar_nehay_belmokhalsa.pdf')"><img src="https://img.icons8.com/small/16/ffffff/print.png"/>Print Form</button>
    </div>
  </div>
  <!--Modal-->
  <div class="modal fade" id="vacation4" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">إقرار نهائى بالمخالصة</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <embed
          type="application/pdf"
          src="https://unico-egypt.com/assets/forms/ekrar_nehay_belmokhalsa.pdf#toolbar=0"
          width="100%"
          height="400px" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="content">
      <h2 class="title">طلب تحويلات <br> Transfers Request</h2>
      <button class="btn" data-toggle="modal" data-target="#Transfers"><img src="../../../../assets/img/dashboard/preview-icon.png" />Preview Form</button>
      <a class="btn" href="https://unico-egypt.com/assets/forms/Transfer_Request.xlsx" download="Transfer_Request.xlsx">Download Form</a>
    </div>
  </div>
  <!--Modal-->
  <div class="modal fade" id="Transfers" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">طلب تحويلات</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=https://unico-egypt.com/assets/forms/Transfer_Request.xlsx#toolbar=0' width='100%' height='565px' frameborder='0'></iframe>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="content">
      <h2 class="title">تسوية العهدة<br>Adjustment Request</h2>
      <button class="btn" data-toggle="modal" data-target="#Adjustment"><img src="../../../../assets/img/dashboard/preview-icon.png" />Preview Form</button>
      <a class="btn" href="https://unico-egypt.com/assets/forms/ADJUSTMENT.xls" download="ADJUSTMENT.xls">Download Form</a>
    </div>
  </div>
  <!--Modal-->
  <div class="modal fade" id="Adjustment" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">تسوية العهدة</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=https://unico-egypt.com/assets/forms/ADJUSTMENT.xls#toolbar=0' width='100%' height='565px' frameborder='0'></iframe>
          <embed
          type="application/pdf"
          src="https://unico-egypt.com/assets/forms/ADJUSTMENT.xls#toolbar=0"
          width="100%"
          height="400px" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</main>
