<section class="well">
  <div class="well-header">
    <div class="text">
      <div class="title container">
        <h1 data-aos="fade-left">WELL STIMULATION</h1>
      </div>
    </div>
  </div>
  <div class="well-content">
    <div class="container">
      <div class="content row">
        <div class="text col-md-6 col-sm-12">
          <p data-aos="fade-up">
            The technology is a mechanical radial drilling with a predicted channel trajectory. It allows to drill radial channels up to 15 m long with a diameter of 69 mm, it is possible to create up to 4 channels within one horizon. The modular design allows to create skeleton-like, branched channels with a predicted trajectory in both open (limestone) and cased hole during the secondary drilling of productive reservoirs of oil and gas condensate fields.
          </p>
          <hr>
          <p data-aos="fade-down">
            Special injection sub, improves the transporting ability of the drilling fluid and reduces the risk of sticking, including differential. Running into the well of the assembly is carried out on tubing, coiled tubing or drill pipes.
          </p>
        </div>
        <div class="img col-md-6 col-sm-12">
          <img data-aos="fade-left" src="../../../assets/img/drilling/well/radial-machine.jpg" alt="Loading...">
          <span data-aos="fade-up">Radial Drilling Machine</span>
        </div>
      </div>
      <hr>
      <div class="content1 row">
        <div class="img col-md-6 col-sm-12">
          <img data-aos="fade-down" src="../../../assets/img/drilling/well/Well Stimulation.jpg" alt="Loading...">
        </div>
        <div class="text col-md-6 col-sm-12">
          <h4 data-aos="fade-up">We provide bench testing services (drilling and milling) using small engines</h4>
          <ul data-aos="fade-down">
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">FLUIDS</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">DOWNHOLE TOOLS</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">SOFTWARE</li>
            <li><img src="../../../../assets/img/icons/dot-logo.png" alt="">ENGINEERING</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
